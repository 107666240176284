<template>
  <div
      v-for="(item, index) in options_filterChips"
      :key="`dark_radio_filter_chips__${name}_${index}`"
  >
    <label
        class="chip-personalized"
        :class="{ 'chip-personalized-selected': isSelected(item) }"
        :for="`dark_radio_filter_chips__${name}__${index}`">
      <input
          type="radio"
          :value="valueObj==null? item : item[valueObj]"
          :name="name"
          :id="`dark_radio_filter_chips__${name}__${index}`"
          style="display: none"
          :checked="valueObj==null? item === modelValue : item[valueObj] === modelValue"
          @click="toggleRadio(index)"
      >
      {{ item.name }}
    </label>
  </div>
</template>

<script>
import { defineComponent } from "vue"
export default defineComponent({
  name: "DarkRadioFilterChip",

  emits: ['update:modelValue', 'filterChanged'],

  props: ['options_filterChips', 'modelValue', 'name', 'valueObj'],

  setup(props, { emit }) {
    const isSelected = (item) => {
      return props.valueObj === null ? item === props.modelValue : item[props.valueObj] === props.modelValue;
    }

    const toggleRadio = (index) => {
      let selectedValue
      if (props.valueObj != null) {
        selectedValue = props.options_filterChips[index][props.valueObj];
      } else {
        selectedValue = props.options_filterChips[index];
      }
      if (selectedValue === props.modelValue) {
        // Si el mismo valor está seleccionado, no se hace nada
        return
      }
      emit("update:modelValue", selectedValue);
      emit("filterChanged")
    };

    return { toggleRadio, isSelected};
  },
})
</script>

<style scoped>
</style>