<template>
  <section class="cartadigital">
    <!--  <WhatsappIconComponent-->
    <!--      :phone="whatsapp_phone"-->
    <!--  ></WhatsappIconComponent>-->
    <section class="cartadigital__hero">
    </section>
    <section class="cartadigital__filters section-main">
      <h1>{{ $t('carta.txt_explora_nuestra_carta') }}</h1>
      <div class="filters">
        <ChipMobileComponent
            :optionsSelect="rawListServCategories"
            :name="'select_categories_local'"
            :required=false
            :placeholder="''"
            :bg_color="'white'"
            :valueObj="'name'"
            v-model="auxCategSelected"
            @dataFiltered="selectedAuxCateg"
        >
        </ChipMobileComponent>
        <div class="filter__chips">
          <label
              class="chip-personalized"
              :class="{ 'chip-personalized-selected': categorySelected === null }"
              :for="`todas__categorias`"
          >
            <input
                type="radio"
                :value=null
                :name="'dr_filter_chips_carta_local'"
                :id="`todas__categorias`"
                style="display: none"
                v-model="categorySelected"
                @change="selectedRadioBtn"
            >
            {{ $t('placeholder.txt_mostrar_todo') }}
          </label>
          <DarkRadioFilterChip
              :name="'dr_filter_chips_carta_local'"
              :valueObj="null"
              :options_filterChips="rawListServCategories"
              v-model="categorySelected"
              @filterChanged="selectedRadioBtn"
          >
          </DarkRadioFilterChip>
        </div>
        <SearchComponent
            :placeholder="$t('placeholder.txt_buscar_por_plato')"
            v-model="search_input"
            @searchChanged="changedSearchInfo"
        >
        </SearchComponent>
      </div>
    </section>
    <section
        class="cartadigital__dish section-main"
        v-for="(item, index) in listCategorias"
        :id="'c1'+ item.id"
        :key="index"
    >
      <div
          v-show="listFilteredProducts.filter(obj => {
            if (obj.category === item.id) {
              return item
            }
          }).length > 0"
      >
        <h2 :id="`subtitulo_carta__${item.id}`">{{ item.name }}</h2>
        <section class="dish__list">
          <ProductoCarta
              v-for="(product, index2) in listFilteredProducts.filter(obj => {
              if (obj.category === item.id) {
                return obj
              }
            })"
              :key="'pro' + index2"
              :id="`product${product.id}`"
              @click.stop="setNewProductSelected(product)"
              :product="product"
          >
          </ProductoCarta>
        </section>
      </div>
    </section>
    <MobileFooterComponent></MobileFooterComponent>
  </section>
  <transition name="slide-fade">
    <ModalProductCustomization
        v-if="showModalCustomization"
        :from_payment="false"
        :list_additional_items="list_additional_items"
        :productId="modal_prod_id"
        :selectedItemCarrito=null
        @close="closeModalCustomization"
        @pay="openModalCarrito"
    >
    </ModalProductCustomization>
  </transition>
  <transition name="slide-fade">
  <LoadingBigComponent
  v-if="loading"></LoadingBigComponent>
  </transition>
</template>

<script>
import LocalServices from "@/services/local-services"
import {mapMutations, mapState} from 'vuex'
//import { Vue3Lottie } from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import animationData from "../assets/animationViews/product_discount_animation_red.json";
// import WhatsappIconComponent from "@/components/WhatsappIconComponent";
import ProductoCarta from "@/components/ProductoCarta.vue";
import DarkRadioFilterChip from "@/components/new/buttons/DarkRadioFilterChip.vue";
import SearchComponent from "@/components/new/inputs/SearchComponent";
import ModalProductCustomization from "@/components/MODALS/ModalProductCustomization";
import LoadingBigComponent from "@/components/Recursos/LoadingBigComponent";
import MobileFooterComponent from "@/components/mobileFooterComponent.vue";
import ChipMobileComponent from "@/components/new/inputs/ChipMobileComponent.vue";
import {useRoute} from "vue-router";
import {useHead} from "@unhead/vue";

export default {
  components: {
    ChipMobileComponent,
    MobileFooterComponent,
    LoadingBigComponent,
    ModalProductCustomization,
    SearchComponent,
    ProductoCarta,
    // WhatsappIconComponent,
    DarkRadioFilterChip,
},
  setup() {
    const route = useRoute();
    const canonicalUrl = `${process.env.VUE_APP_BASE_URL}${route.path}`;
    useHead({
      title: `Nuestra carta`,
      link: [
        { rel: 'canonical', href: canonicalUrl }
      ],
      meta: [
        {
          name: "description",
          content:
              `Carta de ${process.env.VUE_APP_BUSINESS_COMERCIAL_NAME}`,
        },
        { name: "author", content: "Quick eat!" },
        { name: "copyright", content: "Quick eat!" },

        { name: "robots", content: "index, follow" },
        { name: "googlebot", content: "index, follow" },
        { name: "language", content: "Spanish, English" },
        { name: "rating", content: "General" },
      ],
    });
  },
  async created () {
    this.is_ssr = typeof window === 'undefined';
    if (!this.is_ssr) {
      window.scrollTo(0, 0)
    }
  },
  async mounted() {
    this.getWhatsappPhone()
    this.setIsSelfService(false);
    if (!this.is_ssr) {
      window.scrollTo(0, 0)
    }
    const idCategory = this.$route.params.idCategory;
    const idProduct = this.$route.params.idProduct;

    if (idCategory) {
      this.old_id_cat = parseInt(idCategory);
    }
    if (idProduct) {
      this.old_id_prod = idProduct
    } else {
      this.old_id_prod = 0
    }

    await this.loadCartas();
    await this.loadAdditionalItems();
    await this.loadProdsByParam();
    this.loading = false

  },
  computed: {
    ...mapState(['isAuthenticated', 'typeOrder', 'listLocals', 'orderLocal']),
  },

  data () {
    return {
      animationData,
      rawListServCategories: [],
      listCategorias: [],
      listNewProducts: [],
      listFilteredProducts: [],
      categorySelected: null,
      auxCategSelected: null,
      showModalCustomization: false,
      anim: null,
      old_id_cat: 0,
      old_id_prod: 0,
      whatsapp_phone: '',
      list_additional_items: [],
      search_input: '',
      modal_prod_id: 0,
      loading: true,
      is_ssr: false,
    }
  },
  watch: {
    $route: async function () {
      if (this.$route.name === "menudigitalcat") {
        this.old_id_cat = this.$route.params.idCategory
          this.old_id_prod = 0
        return
      }
      if (this.$route.name === "menudigitalprod") {
        const idProduct = this.$route.params.idProduct;
        let item = document.getElementById(`product${idProduct}`)
        if (item !== null) {
          item.click()
        }
      }
    }
  },
  methods: {
    ...mapMutations(['setShowShoppingCart', 'setIsSelfService']),

    openModalCarrito() {
      this.showModalCustomization = false
      this.setShowShoppingCart(true)
    },
    changedSearchInfo() {
      let search = this.search_input.toLowerCase()
      let temp_array = []
      this.listFilteredProducts = this.listNewProducts.filter(item => {
        let name = item.name.toLowerCase()
        if (name.includes(search)) {
          return item
        }
      })
      for (let i=0; i<this.listFilteredProducts.length; i++) {
        temp_array.push(this.listFilteredProducts[i].category)
      }
      this.categorySelected = null
      this.auxCategSelected = null
      this.listCategorias = this.rawListServCategories

    },
    selectedAuxCateg() {
      if (this.auxCategSelected == null) {
        this.categorySelected = null
        this.listCategorias = this.rawListServCategories
        this.search_input = ''
        this.listFilteredProducts = this.listNewProducts
        return
      }
      this.listCategorias = this.rawListServCategories.filter(item => {
        if (item.id === this.auxCategSelected.id) {
          this.categorySelected = item
          return item
        }
      })
      this.listFilteredProducts = this.listNewProducts
      this.search_input = ''
      this.setNewCategory(this.categorySelected)

    },
    selectedRadioBtn() {
      if (this.categorySelected == null) {
        this.auxCategSelected = null
        this.listCategorias = this.rawListServCategories
        this.search_input = ''
        this.listFilteredProducts = this.listNewProducts
        return
      }
      this.listCategorias = this.rawListServCategories.filter(item => {
        if (item.id === this.categorySelected.id) {
          this.auxCategSelected = item
          return item
        }
      })
      this.listFilteredProducts = this.listNewProducts
      this.search_input = ''
      this.setNewCategory(this.categorySelected)
    },
    getWhatsappPhone() {
      this.whatsapp_phone = process.env.VUE_APP_WHATSAPP_PHONE
    },
    handleAnimation: function (anim) {
      this.anim = anim;
      this.anim.setSpeed(1)
      this.anim.playSegments([0, 50], true)
      this.anim.play()
    },
    gotoSection(idCateg) {
      try {
        let a = document.getElementById(`subtitulo_carta__${idCateg}`)
        a.scrollTop = a.scrollHeight
        if (!this.is_ssr) {
          window.scrollTo(0, a.offsetTop - 85)
        }
      } catch (e) {
        console.log("error", e)
      }

    },
    closeModalCustomization () {
      this.showModalCustomization = false
    },

    backgroundPlaceholderItem(product) {
      let url;
      try {
        // eslint-disable-next-line no-unused-vars
        url = new URL(product.img)
        if (!product.is_available) {
          return {background:`url(${product.img}) center center`,'background-size':'cover', '-webkit-filter': 'grayscale(1)', 'filter': 'grayscale(1)'}
        }
        return {background:`url(${product.img}) center center`,'background-size':'cover' }
      } catch (error) {
        if (!product.is_available) {
          return {background:`url(${process.env.VUE_APP_IMG_PLACEHOLDER}) center center`,'background-size':'cover', '-webkit-filter': 'grayscale(1)', 'filter': 'grayscale(1)'}
        }
        return {background:`url(${process.env.VUE_APP_IMG_PLACEHOLDER}) center center`,'background-size':'cover' }
      }
    },
    async loadCartas () {
      try {
        let response = null
        if (this.orderLocal === null) {
          response = await LocalServices.listCategoriesOfLocal(parseInt(process.env.VUE_APP_FIRST_LOCAL_ID))
        } else {
          response = await LocalServices.listCategoriesOfLocal(this.orderLocal.id)
        }
        if (response.data.success) {
          let promo = response.data.data.filter(carta => {
            if (!carta.deleted && carta.is_promotion) {
              return carta
            }
          })
          promo.sort(function (a, b) {
            if (a.position > b.position) {
              return 1;
            }
            if (a.position < b.position) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });

          let normal = response.data.data.filter(carta => {
            if (!carta.deleted && !carta.is_promotion) {
              return carta
            }
          })
          normal.sort(function (a, b) {
            if (a.position > b.position) {
              return 1;
            }
            if (a.position < b.position) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });

          this.rawListServCategories = promo.concat(normal)

          if (this.old_id_cat === 0) {
            this.listCategorias = this.rawListServCategories
            return
          }

          this.listCategorias = this.rawListServCategories.filter(item => {
            if (item.id === this.old_id_cat) {
              this.categorySelected = item
              this.auxCategSelected = item
              return item
            }
          })
          setTimeout(() => {
            this.gotoSection(this.categorySelected.id)

          }, 300)
        } else {
          this.$toast.error(response.data.message)
        }
      }
      catch (error) {
        console.log(error)
      }
    },

    async loadProdsByParam () {
      try {
        let response = null
        if (this.orderLocal === null) {
          response = await LocalServices.listAllProductsFromLocal(this.typeOrder, parseInt(process.env.VUE_APP_FIRST_LOCAL_ID))
        } else {
          response = await LocalServices.listAllProductsFromLocal(this.typeOrder, this.orderLocal.id)
        }
        if (response.data.success) {
          response.data.data = response.data.data.filter(item => !item.deleted)
          let temp_stuff = this.listCategorias.find(item => item.id.toString() === this.$route.params.idCategory);
          if (temp_stuff !== undefined) {
            this.categorySelected = temp_stuff
          }
          this.listNewProducts = response.data.data.filter(product => {
            if (!product.deleted) {
              return product
            }
          })
          this.listNewProducts.sort(function (a, b) {
            if (a.position > b.position) {
              return 1;
            }
            if (a.position < b.position) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });
          this.listFilteredProducts = [...this.listNewProducts]
          setTimeout(() => {
            if (this.$route.params.idProduct !== undefined) {
              if (this.$route.params.idProduct !== null) {
                let docum = document.getElementById(`product${this.$route.params.idProduct}`)
                if (docum !== null) {
                  docum.click()
                }
              }
            }
          }, 200)

        } else {
          console.log("error")
        }
      } catch (error) {
        console.log("error aqui", error)
      }
    },

    async loadAdditionalItems () {
      let response = null
      if (this.orderLocal === null) {
        response = await LocalServices.listOfAditionalItems(parseInt(process.env.VUE_APP_FIRST_LOCAL_ID))
      } else {
        response = await LocalServices.listOfAditionalItems(this.orderLocal.id)
      }
      if (response.data.success) {
        this.list_additional_items = response.data.data.filter((additional) => {
          if (!additional.deleted) {
            return additional
          }
        })
        for (let i = 0; i < this.list_additional_items.length; i++) {
          this.list_additional_items[i].cantidad = 0
        }
      } else {
        this.$toast.error(response.data.message)
      }
    },

    async setNewCategory(category) {
      if (category.id.toString() === this.old_id_cat.toString()) {
        return
      }
      this.old_id_cat = category.id
      this.categorySelected = category
      if (this.old_id_prod === 0) {
        this.$router.push({
          name: 'menudigitalcat',
          params: {idCategory: category.id},
          path: `/menudigital/${category.id}`
        })
      } else {
        this.$router.push({
          name: 'menudigitalprod',
          params: {idCategory: category.id, idProduct: this.old_id_prod},
          path: `/menudigital/${category.id}/${this.old_id_prod}`
        })
      }
    },

    setNewProductSelected(product) {
      this.categorySelected = this.listCategorias.find(item => {
        if (item.id === product.category) {
          return item
        }
      })
      const idProduct = this.$route.params.idProduct;
      this.old_id_prod = product.id
      if (idProduct) {
        if (idProduct !== product.id.toString()) {
          this.$router.push({
            name: 'menudigitalprod',
            params: {idCategory: this.categorySelected.id, idProduct: product.id},
            path: `/menudigital/${this.categorySelected.id}/${product.id}`
          })
        }
        
      } else {
        this.$router.push({
          name: 'menudigitalprod',
          params: {idCategory: this.categorySelected.id, idProduct: product.id},
          path: `/menudigital/${this.categorySelected.id}/${product.id}`
        })
      }
      this.old_id_prod = 0
      this.modal_prod_id = product.id
      if (!product.is_available) {
        this.$toast.error(this.$t('cartarestaurante.txt_producto_agotado'))
        return
      }
      this.showModalCustomization = true
    },
  }
}
</script>

<style scoped>
.cartadigital__hero{
  background-image: url("https://quickeat.s3.us-west-2.amazonaws.com/local/Barrilito+Express/new_web_files/carta-background-mobile.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 70px;
}

.cartadigital__filters h1{
  font-size: var(--fs-head-small);
  font-weight: var(--bold);
  line-height: 32px;
  text-align: center;
  margin-bottom: 16px;
}

.filters{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.filter__chips{
  display: none;
}

.cartadigital__dish h2{
  font-family: var(--primary-typography);
  font-size: var(--fs-title-small);
  font-weight: var(--semibold);
  letter-spacing: 0.1px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 16px;
}

.dish__list{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

@media screen and (min-width: 600px){
  .cartadigital__hero{
    height: 100px;
  }

  .dish__list{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
}

@media screen and (min-width: 905px){
  .cartadigital__hero{
    height: 150px;
  }

  .cartadigital__filters h1{
    font-size: var(--fs-head-medium);
    line-height: 36px;
  }

  .filters{
    justify-content: flex-start;
    gap: 20px;
  }

  .select__component__body__wrapper{
    display: none;
  }

  .filter__chips{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;
  }

  .cartadigital__dish h2{
    font-size: var(--fs-title-medium);
    line-height: 24px;
    letter-spacing: 0.15px;
  }
}

@media screen and (min-width: 1240px){
  .cartadigital__hero{
    height: 200px;
  }

  .filter__chips{
    gap: 20px;
  }

  .dish__list{
    gap: 24px;
  }
}

@media screen and (min-width: 1440px){
  .cartadigital__hero{
    height: 230px;
  }

  .cartadigital__filters{
    padding: 40px 0 0;
  }

  .cartadigital__filters h1{
    font-size: var(--fs-head-large);
    line-height: 40px;
    margin-bottom: 30px;
  }

  .filters{
    gap: 30px;
  }

  .cartadigital__dish h2{
    font-size: var(--fs-title-large);
    line-height: 28px;
    letter-spacing: 0;
    margin-bottom: 30px;
  }
}
</style>