<template>
  <div class="sub_container_beneficios">
    <div
      class="item_cupon"
      v-for="(item,index) in beneficios"
      :key="index"
    >
      <div class="img_cupon"
        @click="openModal(item)"
        :style="{background:`url(${item.img}) center center`,'background-size':'cover'}"
      >
      </div>
      <div class="body_cupon">
        <div class="cupon_information">
          <p class="tp-subtitle-responsive" @click="openModal(item)">{{item.title}}</p>
          <p class="tp-body-responsive expira" @click="openModal(item)">{{ $t('perfilmisbeneficiosbeneficios.txt_expira') }} {{getFecha(item)}}</p>
        </div>
        <ButtonPrimary
            type="button"
            v-if="!item.cod_coupon"
            :disabled="user.points < item.points && !fromPaymentScreen"
            class="points"
            @click="openModal(item)"
        >{{item.points}} {{ $t('ordeinfocomponent.txt_puntos') }}
        </ButtonPrimary>
        <div class="code" v-if="item.cod_coupon">
          <span class="tp-body-responsive" @click="copy(item.cod_coupon)">{{item.cod_coupon}}</span>
          <span
              class="copy tp-body-responsive"
              @click="copy(item.cod_coupon)"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 18C8.45 18 7.97917 17.8042 7.5875 17.4125C7.19583 17.0208 7 16.55 7 16V4C7 3.45 7.19583 2.97917 7.5875 2.5875C7.97917 2.19583 8.45 2 9 2H18C18.55 2 19.0208 2.19583 19.4125 2.5875C19.8042 2.97917 20 3.45 20 4V16C20 16.55 19.8042 17.0208 19.4125 17.4125C19.0208 17.8042 18.55 18 18 18H9ZM9 16H18V4H9V16ZM5 22C4.45 22 3.97917 21.8042 3.5875 21.4125C3.19583 21.0208 3 20.55 3 20V7C3 6.71667 3.09583 6.47917 3.2875 6.2875C3.47917 6.09583 3.71667 6 4 6C4.28333 6 4.52083 6.09583 4.7125 6.2875C4.90417 6.47917 5 6.71667 5 7V20H15C15.2833 20 15.5208 20.0958 15.7125 20.2875C15.9042 20.4792 16 20.7167 16 21C16 21.2833 15.9042 21.5208 15.7125 21.7125C15.5208 21.9042 15.2833 22 15 22H5Z" fill="currentColor"/>
            </svg>
        </span>
        </div>
      </div>
    </div>
    <div class="loading" v-if="loadingCoupon" >
      <p class="tp-subtitle-responsive">{{ $t('forgotpassword.txt_cargando_dat') }}</p>
    </div>
    <div class="loading" v-if="!loadingCoupon&&beneficios.length===0" >
      <p class="tp-subtitle-responsive">{{ $t('perfilmisbeneficiosbeneficios.txt_sin_cupones') }}</p>
    </div>
    <ModalSinPuntos
      v-if="showModalSinPuntos"
      @close="showModalSinPuntos=false"
    ></ModalSinPuntos>
    <transition name="slide-fade">
      <TwoButtonGenericModal
          v-if="showModalConfirm"
          :title="this.$t('perfilmisbeneficiosbeneficios.txt_estimado_cliente')"
          :content="this.$t('perfilmisbeneficiosbeneficios.txt_felicitaciones_desea_canjear_sus_puntos')"
          :accept="this.$t('perfilmisbeneficiosbeneficios.txt_si_canjear_mi_premio')"
          :cancel="this.$t('perfil.txt_no_regresar')"
          @close_modal="showModalConfirm=false"
          @accepted_modal="activarCupon"
      >
      </TwoButtonGenericModal>
    </transition>
  </div>
</template>

<script>
import CuponServices from "@/services/cupon-services"
import moment from 'moment'
import {defineAsyncComponent} from "vue";
import TwoButtonGenericModal from "@/components/MODALS/TwoButtonGenericModal";
import ButtonPrimary from "@/components/new/buttons/ButtonPrimary.vue";
import {mapState} from "vuex";

export default {
  emits: ['copy'],
  props: {
    loadPointsCircle: Function,
    puntos: Number,
    fromPaymentScreen: Boolean,
  },
  components: {
    TwoButtonGenericModal,
    ModalSinPuntos: defineAsyncComponent(() => import("./PerfilMisBeneficiosMODALSinPuntos")),
    ButtonPrimary
},
  computed: {
    ...mapState(['user'])
  },

  data () {
    return {
      loading: false,
      loadingCoupon:false,
      showModalSinPuntos: false,
      showModalConfirm: false,
      selectCupon: '',
      type_discount: {
        '0': 'monto',
        '1': 'porcentaje',
        '2': 'delivery',
        '3': 'producto_gratis',
        '4': 'producto_parcial',
        '5': 'producto_porcentual'
      },
      beneficios: [
      ],
    }
  },
  async created () {
    try {
      this.loadingCoupon=true
      await this.loadListCoupons()
    } catch (error) {
      console.log(error)
    }finally{
      this.loadingCoupon=false
    }
  },
  methods: {
    async loadListCoupons () {
      this.beneficios = []
      let response = await CuponServices.listaDeCupones()
      if (response.data.success) {
        response.data.data = response.data.data.sort(function (a, b) {
          if (a.id > b.id) {
            return 1;
          }
          if (a.id < b.id) {
            return -1;
          }
          return 0;
        });
        this.beneficios = await response.data.data.filter(cupon => {
          let now = new Date()
          let start = new Date(cupon.date_start)
          let end = new Date(cupon.date_end)
          if (cupon.expire_coupon.length > 0) {
            end = new Date(cupon.expire_coupon)

          }
          if (cupon.is_available && (end >= now) && (start <= now)) {
            return true
          }
        })
      }

    },
    copy (coupon) {
      let dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = coupon;
      dummy.select();

      let res = document.execCommand("copy");
      if (res) {
        this.$toast.success(this.$t('perfilmisbeneficiosbeneficios.txt_se_copio_el_codigo_del_cupon'))
      }
      document.body.removeChild(dummy);
      this.$emit('copy',coupon)
    },
    getFecha (cupon) {
      if (cupon.cod_coupon.length > 0) {
        return moment(cupon.expire_coupon).locale('es').format("DD/MM/YYYY, hh:mm a");
      } else {
        return moment(cupon.date_end).locale('es').format("DD/MM/YYYY, hh:mm a");
      }
    },
    openModal (cupon) {
      if (this.puntos > 0) {
        if (cupon.cod_coupon.length < 3 && !this.loading) {
          this.selectCupon = cupon
          this.showModalConfirm = true
        }
      } else {
        this.showModalSinPuntos = true
      }
    },
    async activarCupon () {
      this.showModalConfirm = false
      try {
        this.loading = true
        let response = await CuponServices.activarUnCupon(this.selectCupon.id)
        console.log(response)
        if (response.data.success) {
          this.$toast.success(this.$t('perfilmisbeneficiosbeneficios.txt_se_activo_cupon'))
          await this.loadListCoupons()
          await this.loadPointsCircle()
        } else {
          this.$toast.error(response.data.message)
        }

      } catch (error) {
        this.$toast.error(this.$t('perfilmisbeneficiosbeneficios.txt_ocurrio_un_error_al'))
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.sub_container_beneficios{
  height: 100%;
  max-height: 500px;
  overflow-y: auto;
}

.item_cupon{
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  border-bottom: 1px solid var(--border-gray);
  padding: 10px 0;
}

.item_cupon:last-child{
  border-bottom: none;
}

.body_cupon{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}

.img_cupon{
  min-width: 72px;
  min-height: 72px;
  border: 1px solid var(--border-gray);
  object-fit: cover;
  border-radius: 8px;
  overflow: hidden;
}

.tp-subtitle-responsive{
  margin-bottom: 6px;
}

.code{
  display: flex;
  align-items: center;
  gap: 10px;
}

.code svg{
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  cursor: pointer;
  color: var(--text-black);
  transition: all .3s linear;
}

.expira{
  color: var(--primary);
}

.loading{
  text-align: center;
  align-items: center;
  min-height: 100px;
}

.loading p{
  margin-top: 20px;
}

@media screen and (min-width: 1240px){
  .code svg:hover{
    color: var(--primary);
  }
}
</style>

