<template>
  <div class="container_list_reservas" v-if="reserves.length > 0">
    <div class="list_reservas__date">
      <p>{{ $t('perfilmisreservastab.txt_dia') }}</p>
      <p><b>XX</b></p>
      <p>{{ $t('perfilmisreservastab.txt_mes') }}</p>
    </div>
    <hr>
    <div class="list_reservas__information">
      <p>{{ $t('perfilmisreservastab.txt_local_dot') }} <span>XXX</span></p>
      <p>{{ $t('perfilmisreservastab.txt_zona') }} <span>XXX</span></p>
      <p>{{ $t('perfilmisreservastab.txt_cantidad') }} <span>XXX</span></p>
      <p>{{ $t('perfilmisreservastab.txt_hora') }} <span>XX:XX</span></p>
    </div>
    <hr>
    <p class="list_reservas__code">{{ $t('perfilmisreservastab.txt_codigo') }} <span>XXXXX</span></p>
    <div class="list_reservas__buttons">
      <ButtonPrimary
          type="button"
          @click="openModalCancelBooking"
      >
        {{ $t('buttons.txt_cancelar') }}
      </ButtonPrimary>
      <ButtonOutline
          type="button"
          :disabled="true"
          @click="openModalReschedule"
      >
        {{ $t('modalreschedule.txt_reprogramar') }}
      </ButtonOutline>
    </div>
  </div>
  <div class="not-reserve" v-else>
    <p>{{ $t('perfilmisreservastab.txt_no_hay_reservas_que_mostrar') }}</p>
    <ButtonPrimary
        type="button"
        @click="this.$router.push({name: 'reservas'})"
    >
      {{ $t('buttons.txt_iniciar_reserva') }}
    </ButtonPrimary>
  </div>
  <transition name="slide-fade">
    <ModalCancelBooking
        v-if="showModalCancelBooking"
        @close="showModalCancelBooking = false"
    />
  </transition>
  <transition name="slide-fade">
    <ModalReschedule
      v-if="showModalReschedule"
      @close="showModalReschedule = false"
    />
  </transition>
</template>

<script setup>
  import {defineProps, ref} from "vue";
  import ButtonPrimary from "@/components/new/buttons/ButtonPrimary.vue";
  import ButtonOutline from "@/components/new/buttons/ButtonOutline.vue";
  import ModalCancelBooking from "@/components/MODALS/ModalCancelBooking.vue";
  import ModalReschedule from "@/components/MODALS/ModalReschedule.vue";

  let showModalCancelBooking = ref(false);
  let showModalReschedule = ref(false);

  defineProps(['reserves']);

  const openModalCancelBooking = () => showModalCancelBooking.value = true;
  const openModalReschedule = () => showModalReschedule.value = true;
</script>

<style scoped>
  .container_list_reservas{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: auto;
    max-width: 300px;
    margin-inline: auto;
    box-shadow: var(--box-shadow);
    border-radius: 8px;
    padding: 20px 10px;
  }

  .list_reservas__date{
    display: flex;
    align-items: center;
    gap: 4px;
  }

  hr{
    width: 150px;
    border-color: var(--border-gray);
  }

  .list_reservas__information p{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 150px;
    font-weight: var(--medium);
  }

  .list_reservas__code p{ font-weight: var(--medium); }

  .list_reservas__information span,
  .list_reservas__code span{ font-weight: var(--regular); }

  .list_reservas__buttons{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .list_reservas__buttons button{ width: 150px; }

  .not-reserve{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 80%;
    border: 1px dashed;
    border-radius: 8px;
    padding: 20px;
    color: #8F8F8F;
    text-align: center;
    cursor: pointer;
    transition: color .3s linear;
    margin-inline: auto;
  }

  .not-reserve:hover{
    color: var(--primary);
  }

  @media only screen and (min-width: 1024px){
    .container_list_reservas{
      flex-direction: row;
      max-width: 750px;
      padding: 30px;
      gap: 20px;
    }
    .list_reservas__date{
      flex-direction: column;
      justify-content: center;
      gap: 5px;
    }
    hr{
      width: 2px;
      height: 100px;
    }
    .list_reservas__buttons{ gap: 20px; }
  }
</style>