<template>
  <form @submit.prevent="resetearClave" class="perfil_cambiar_clabe">
    <p class="tp-body-responsive">{{ $t('perfil.txt_si_requieres_modificar' )}}</p>
    <div class="form-cambiar-clave">
      <PasswordComponent
          :placeholder="$t('placeholder.txt_contrasena_actual')"
          :required="true"
          :bg_color="'#F7F7F7'"
          :disabled="loading"
          v-model="old_password"
      >
      </PasswordComponent>
      <PasswordComponent
          :placeholder="$t('placeholder.txt_nueva_contrasena')"
          :required="true"
          :bg_color="'#F7F7F7'"
          :disabled="loading"
          v-model="new_password"
      >
      </PasswordComponent>
      <PasswordComponent
          :placeholder="$t('placeholder.txt_confirmar_nueva_contrasena')"
          :required="true"
          :bg_color="'#F7F7F7'"
          :disabled="loading"
          v-model="new_password"
      >
      </PasswordComponent>
      <ButtonPrimary
          type="submit"
          :loading="loading"
      >
        {{ $t('buttons.txt_confirmar_contrasena') }}
      </ButtonPrimary>
      <ButtonSecundary
          type="button"
          @click="sendEmailRecover"
          :loading="loading"
      >
        {{ $t('buttons.txt_recuperar_contrasena_por_correo') }}
      </ButtonSecundary>
    </div>
  </form>
</template>

<script>
import PasswordComponent from "@/components/new/inputs/PasswordComponent.vue";
import User from "@/services/user-services";
import ButtonPrimary from "@/components/new/buttons/ButtonPrimary.vue";
import ButtonSecundary from "@/components/new/buttons/ButtonSecundary.vue";
import {mapState} from "vuex";

export default {
  name: "PerfilCambiarClave",
  components: {
    ButtonPrimary,
    PasswordComponent,
    ButtonSecundary
},
  data () {
    return {
      old_password: '',
      new_password: '',
      loading: false,
    }
  },
  computed: {
    ...mapState(['user'])
  },
  methods: {

    async sendEmailRecover() {
      this.loading=true
      let response = await User.sendMailPassword({'email': this.user.email})
      console.log(response.data)
      if (response.data.success) {
        this.$toast.success(this.$t('perfilcambiarclave.txt_correo_para_cambio_de_contrasena'))
        this.loading=false
      } else {
        this.$toast.error(response.data.message)
        this.loading=false
      }
    },
    async resetearClave() {
      if (this.new_password.length < 6) {
        this.$toast.error(this.$t('perfilcambiarclave.txt_la_contrasena_debe_tener'))
        return
      }
      this.loadingCambiar = true
      let response = await User.setPassword({old_password: this.old_password, new_password: this.new_password})
      this.loadingCambiar=false

      if(response.data.success) {
        console.log(response.data)
        this.$toast.success(this.$t('perfilcambiarclave.txt_se_cambio_su_contrasena'))
        this.formClave=false
        return
      }
      console.log(response.data)
      this.$toast.error(response.data.message)
    }
  }
}
</script>

<style scoped>
.tp-body-responsive{
  margin-bottom: 20px;
}

.form-cambiar-clave{
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
}

.form-cambiar-clave button{
  flex: 0 0 fit-content;
}

@media screen and (min-width: 600px){
  .form-cambiar-clave{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }

  .form-cambiar-clave button{
    width: fit-content;
  }
}

@media screen and (min-width: 905px){
  .form-cambiar-clave > *{
    grid-column: 1 / 2;
  }

  .form-cambiar-clave button:last-child{
    grid-column: 2 / -1;
    grid-row: 1 / 2;
  }
}

@media screen and (min-width: 1240px){
  .form-cambiar-clave{
    grid-template-columns: repeat(auto-fit, minmax(200px, 275px));
  }
}
</style>