<template>
  <div class="primary-counter">
    <svg
         v-if="quantity === 1 && fromShoppingCart"
         @click="deleteItem"
         :style="loading? 'cursor: not-allowed': ''"
         width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 21C6.45 21 5.97917 20.8042 5.5875 20.4125C5.19583 20.0208 5 19.55 5 19V6C4.71667 6 4.47917 5.90417 4.2875 5.7125C4.09583 5.52083 4 5.28333 4 5C4 4.71667 4.09583 4.47917 4.2875 4.2875C4.47917 4.09583 4.71667 4 5 4H9C9 3.71667 9.09583 3.47917 9.2875 3.2875C9.47917 3.09583 9.71667 3 10 3H14C14.2833 3 14.5208 3.09583 14.7125 3.2875C14.9042 3.47917 15 3.71667 15 4H19C19.2833 4 19.5208 4.09583 19.7125 4.2875C19.9042 4.47917 20 4.71667 20 5C20 5.28333 19.9042 5.52083 19.7125 5.7125C19.5208 5.90417 19.2833 6 19 6V19C19 19.55 18.8042 20.0208 18.4125 20.4125C18.0208 20.8042 17.55 21 17 21H7ZM17 6H7V19H17V6ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z" fill="currentColor"/>
    </svg>
    <svg
         v-if="quantity > 1 || !fromShoppingCart"
         @click="menos"
         :style="(loading || quantity === 1) ? 'cursor: not-allowed; opacity: 0.2;' : ''"
         width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 13C5.71667 13 5.47917 12.9042 5.2875 12.7125C5.09583 12.5208 5 12.2833 5 12C5 11.7167 5.09583 11.4792 5.2875 11.2875C5.47917 11.0958 5.71667 11 6 11H18C18.2833 11 18.5208 11.0958 18.7125 11.2875C18.9042 11.4792 19 11.7167 19 12C19 12.2833 18.9042 12.5208 18.7125 12.7125C18.5208 12.9042 18.2833 13 18 13H6Z" fill="currentColor"/>
    </svg>
    <p class="tp-counter-responsive">{{quantity}}</p>
    <svg
        :style="loading? 'cursor: not-allowed': ''"
        @click="mas"
        width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.0835 11.9166H5.50016C5.24044 11.9166 5.02273 11.8287 4.84704 11.653C4.67134 11.4773 4.5835 11.2596 4.5835 10.9999C4.5835 10.7402 4.67134 10.5225 4.84704 10.3468C5.02273 10.1711 5.24044 10.0833 5.50016 10.0833H10.0835V5.49992C10.0835 5.2402 10.1713 5.02249 10.347 4.84679C10.5227 4.6711 10.7404 4.58325 11.0002 4.58325C11.2599 4.58325 11.4776 4.6711 11.6533 4.84679C11.829 5.02249 11.9168 5.2402 11.9168 5.49992V10.0833H16.5002C16.7599 10.0833 16.9776 10.1711 17.1533 10.3468C17.329 10.5225 17.4168 10.7402 17.4168 10.9999C17.4168 11.2596 17.329 11.4773 17.1533 11.653C16.9776 11.8287 16.7599 11.9166 16.5002 11.9166H11.9168V16.4999C11.9168 16.7596 11.829 16.9773 11.6533 17.153C11.4776 17.3287 11.2599 17.4166 11.0002 17.4166C10.7404 17.4166 10.5227 17.3287 10.347 17.153C10.1713 16.9773 10.0835 16.7596 10.0835 16.4999V11.9166Z" fill="currentColor"/>
    </svg>
  </div>
</template>

<script>
export default {
  props: ['quantity', 'loading', 'fromShoppingCart'],
  emits: ['menos', 'mas', 'deleteItem'],
  data () {
    return {
    }
  },

  methods: {
    menos () {
      if (this.loading) {
        return
      }
      if (this.quantity > 1) {
        this.$emit("menos")
      }
    },
    mas () {
      if (this.loading) {
        return
      }
      this.$emit("mas")
    },
    deleteItem() {
      if (this.quantity > 1) {
        return;
      }
      if (this.loading) {
        return
      }
      this.$emit('deleteItem')
    }

  }
}
</script>

<style scoped>
</style>