<template>
  <div class="modal__bg" @click="$emit('no')">
    <div class="modal__container-lg" @click.stop>
      <img src="@/assets/user/icon_campana.svg" alt="campana">
      <p>{{ $t('modalAddCouponProduct.txt_deseas_usar_tu_cupon') }} {{title_coupon}} {{ $t('modalAddCouponProduct.txt_en_este_pedido') }}
      </p>
      <div class="modal__buttons">
        <ButtonTexto
            type="button"
            @click="$emit('no')"
        >
          {{ $t('dropdownaddress.txt_no') }}
        </ButtonTexto>
        <ButtonPrimary
            type="button"
            @click="clickOk"
        >
          {{ $t('dropdownaddress.txt_si_quiero_usarlo') }}
        </ButtonPrimary>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonTexto from "@/components/new/buttons/ButtonTexto";
import ButtonPrimary from "@/components/new/buttons/ButtonPrimary";
export default {
  name: "ModalAddCouponProduct",
  emits: ['no', 'productAdded'],
  props: ['title_coupon'],
  components: {
    ButtonPrimary,
    ButtonTexto
  },
  methods: {
    clickOk () {
      this.$emit('productAdded')
    }
  }

}
</script>

<style scoped>
.modal__container-lg{
  overflow: visible;
}

img{
  position: absolute;
  top: -15px;
  right: -15px;
}

p{
  text-align: center;
}
</style>