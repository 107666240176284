<template>
  <div class="input-container">
    <label class="input-label tp-label-responsive activated"
           :style="{background : `linear-gradient(180deg, ${bg_color} 0%, ${bg_color} 45%, white 45.1%, white 100%)`}">
      {{ placeholder }}
    </label>
    <textarea :value="modelValue"
              class="input__class tp-body-responsive"
              @input="onChanged"
              :required="required"
              rows="3"
              maxlength="1500"
              :placeholder=aux_placeholder
    />
  </div>
</template>

<script>
import { defineComponent } from "vue"
export default defineComponent({
  name: "TextAreaComponent",

  emits: ['update:modelValue', 'inputUpdate'],

  props: {
    modelValue: String,
    placeholder: {
      type: String,
      default: ''
    },
    aux_placeholder: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    bg_color: {
      type: String,
      default: 'white'
    }

  },

  setup(props, { emit }) {
    function onChanged(e) {
      emit('update:modelValue', e.currentTarget.value);
      emit('inputUpdate')
    }

    return {
      onChanged
    }
  }
})
</script>

<style scoped>
  .input__class{
    height: 175px;
    padding: 10px;
  }
</style>