<template>
  <div class="modal__bg" @click="closeModal">
    <div class="wrapper__reschedule" @click.stop>
      <h2>{{ $t('modalreschedule.txt_reprogramar_reserva') }}</h2>
      <p>{{ $t('modalreschedule.txt_la_reprogramacion_sera_disponible') }}</p>
      <div class="reschedule__componets">
        <date-picker
            v-if="isClient"
            value-type="YYYY-MM-DD HH:mm"
            format="DD-MM hh:mm a"
            lang="es"
            type="datetime"
            :placeholder="$t('placeholder.txt_fecha')"
        >
        </date-picker>
        <WhiteSelectComponent
            :optionsSelect="listTimes"
            :bg_color="'white'"
            :required=true
            :placeholder="$t('placeholder.txt_horario')"
            :valueObj="'name'"
            v-model="selectTime"
        >
        </WhiteSelectComponent>
        <WhiteSelectComponent
            :optionsSelect="listAreas"
            :bg_color="'white'"
            :required=true
            :placeholder="$t('placeholder.txt_zona')"
            :valueObj="'name'"
            v-model="selectArea"
        >
        </WhiteSelectComponent>
      </div>
      <div class="reschedule__buttons">
        <ButtonTexto
            type="button"
            @click="closeModal"
        >
          {{ $t('dropdownaddress.txt_regresar') }}
        </ButtonTexto>
        <ButtonPrimary
            type="button"
        >
          {{ $t('modalreschedule.txt_reprogramar') }}
        </ButtonPrimary>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { defineEmits, ref, onMounted } from "vue"
  import ButtonTexto from "@/components/new/buttons/ButtonTexto.vue";
  import ButtonPrimary from "@/components/new/buttons/ButtonPrimary.vue";
  import WhiteSelectComponent from "@/components/new/inputs/WhiteSelectComponent.vue";

  let listTimes = ref([]);
  let listAreas = ref([]);
  let selectTime = ref(null);
  let selectArea = ref(null);
  let isClient = ref(false);

  onMounted(() => {
    isClient.value = true;
  })

  const emit = defineEmits(['close']);

  const closeModal = () => emit('close');
</script>

<style scoped>
  .wrapper__reschedule{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
    max-width: 500px;
    margin-inline: auto;
    background-color: var(--text-white);
    border-radius: 16px;
    padding: 30px 15px;
  }

  h2{
    margin-bottom: 0;
    font-size: 1.375rem;
    text-align: center;
  }

  p{
    font-size: 1rem;
    text-align: center;
  }

  .reschedule__componets{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
  }

  .reschedule__componets > *{ flex: 1 0 8rem; }

  .reschedule__buttons{
    display: flex;
    gap: 20px;
  }

  @media only screen and (min-width: 768px){
    .wrapper__reschedule{
      gap: 30px;
      max-width: 600px;
      padding: 40px;
    }
  }
</style>