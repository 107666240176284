<template>
<div>
  <a :href="myLink" class="whatsapp" target="_blank">
    <svg class="whatsapp-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.2793 13.6438C17.2365 13.6232 15.6326 12.8334 15.3476 12.7308C15.2312 12.689 15.1066 12.6483 14.974 12.6483C14.7575 12.6483 14.5755 12.7562 14.4338 12.9682C14.2736 13.2063 13.7886 13.7733 13.6387 13.9426C13.6192 13.965 13.5925 13.9917 13.5765 13.9917C13.5621 13.9917 13.3139 13.8895 13.2387 13.8568C11.5182 13.1095 10.2123 11.3123 10.0332 11.0092C10.0077 10.9656 10.0066 10.9458 10.0064 10.9458C10.0127 10.9228 10.0705 10.8648 10.1004 10.8348C10.1877 10.7484 10.2824 10.6345 10.374 10.5243C10.4173 10.472 10.4607 10.4198 10.5034 10.3705C10.6362 10.2159 10.6954 10.0959 10.764 9.95691L10.7999 9.88469C10.9673 9.55205 10.8243 9.27133 10.7781 9.18069C10.7402 9.10483 10.063 7.47041 9.99096 7.29869C9.81782 6.88433 9.58903 6.69141 9.27111 6.69141C9.24161 6.69141 9.27111 6.69141 9.14739 6.69662C8.99675 6.70298 8.17639 6.81098 7.81368 7.03962C7.42903 7.28212 6.77832 8.05512 6.77832 9.41455C6.77832 10.638 7.55475 11.7933 7.88811 12.2326C7.89639 12.2437 7.91161 12.2662 7.93368 12.2985C9.21032 14.1629 10.8018 15.5446 12.4152 16.1891C13.9684 16.8095 14.7039 16.8813 15.122 16.8813H15.1221C15.2978 16.8813 15.4385 16.8675 15.5625 16.8553L15.6412 16.8478C16.1778 16.8002 17.357 16.1892 17.6252 15.4438C17.8365 14.8568 17.8922 14.2153 17.7516 13.9825C17.6553 13.8243 17.4894 13.7446 17.2793 13.6438Z" fill="currentColor"/>
      <path d="M12.1953 1C6.23698 1 1.38955 5.811 1.38955 11.7245C1.38955 13.6371 1.90141 15.5094 2.87105 17.1479L1.01513 22.6226C0.980555 22.7246 1.00627 22.8375 1.08177 22.9144C1.13627 22.97 1.2102 23 1.2857 23C1.31463 23 1.34377 22.9956 1.3722 22.9866L7.08077 21.1726C8.64291 22.0072 10.4085 22.4478 12.1953 22.4478C18.1531 22.4479 23 17.6374 23 11.7245C23 5.811 18.1531 1 12.1953 1ZM12.1953 20.2139C10.5139 20.2139 8.88541 19.7284 7.48555 18.8098C7.43848 18.7789 7.38384 18.763 7.32884 18.763C7.29977 18.763 7.27063 18.7674 7.24227 18.7764L4.38263 19.6854L5.30577 16.9619C5.33563 16.8738 5.3207 16.7766 5.2657 16.7014C4.1997 15.2449 3.6362 13.5239 3.6362 11.7245C3.6362 7.04286 7.47577 3.234 12.1952 3.234C16.9141 3.234 20.7532 7.04286 20.7532 11.7245C20.7533 16.4056 16.9142 20.2139 12.1953 20.2139Z" fill="currentColor"/>
    </svg>
  </a>
</div>
</template>

<script>
export default {
  props: ['phone'],
  name: "WhatsappIconComponent",
  computed: {
    myLink() {
      return `https://api.whatsapp.com/send/?phone=51${this.phone}&text=${process.env.VUE_APP_MENSAJE_WHATSAPP}&app_absent=0`
    },

  }
}
</script>

<style scoped>
.whatsapp {
  position:fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width:60px;
  height:60px;
  bottom:20px;
  right:20px;
  background-color:#25d366;
  color:#FFF;
  border-radius:50px;
  text-align:center;
  font-size:30px;
  z-index:3;
}

.whatsapp-icon {
  min-width: 35px;
  color: var(--text-white);
}


</style>