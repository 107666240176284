<template>
  <div class="input-container">
    <input
        class="input__class tp-body-responsive"
        :class="{'activated-disabled': (modelValue != null && modelValue.length > 0 && disabled)}"
        :value="modelValue"
        :placeholder="placeholder"
        @input="onChanged"
        :disabled="disabled"
        :required="required"
        :max="max"
        :min="min"
        type="number"
        :step="step"
        @wheel="preventWheelScroll"
    />
    <label
        class="input-label tp-label-responsive"
        :class="{'activated': (modelValue != null && modelValue.length > 0) }"
        :style="{background : `linear-gradient(180deg, var(text-white) 0%, var(text-white) 45%, white 45.1%, white 100%)`}"
    >
      {{ placeholder }}
    </label>
  </div>
</template>

<script>
import { defineComponent } from "vue"
export default defineComponent({
  name: "InputNumberComponent",

  emits: ['update:modelValue', 'inputUpdate'],

  props: ['modelValue', 'placeholder', 'required', 'disabled', 'step', 'bg_color', 'max', 'min'],

  setup(props, { emit }) {
    function onChanged(e) {
      emit('update:modelValue', e.currentTarget.value);
      emit('inputUpdate', e.currentTarget.value)
    }
    function preventWheelScroll(event) {
      event.preventDefault();
    }
    return {
      onChanged, preventWheelScroll
    }
  }
})
</script>

<style scoped>
</style>