<template>
  <button
      class="button"
      :class="{ 'button-padding-left': hasSvg }"
      :disabled="disabled || loading"
  >
    <SpinnerComponent
        :spinner-color="'var(--primary)'"
        :type="'small'"
        v-if="loading"
    />
    <slot></slot>
  </button>
</template>

<script setup>
import SpinnerComponent from "@/components/new/buttons/SpinnerComponent";
import { button } from "@/composables/button";
import { defineProps, useSlots } from 'vue';

defineProps(['disabled', 'loading']);

const { hasSvg } = button(useSlots());
</script>

<style scoped>
button{
  background-color: var(--text-white);
  border: 1px solid;
  color: var(--primary);
}

button:hover{
  border-color: inherit;
}

button:hover::before{
  background-color: var(--buttonHoverOutline);
}

button:active{
  border-color: inherit;
}

button:active::before{
  background-color: var(--buttonActiveOutline);
}

button:disabled{
  background-color: transparent;
  border-color: var(--border-white);
  cursor: not-allowed;
  color: var(--buttonDisabledColor);
}

button[disabled]::before{
  background-color: transparent;
}
</style>