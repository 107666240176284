<template>
  <button
      class="button"
      :class="{ 'button-padding-left': hasSvg }"
      :disabled="disabled || loading"
  >
    <SpinnerComponent
        :spinner-color="'var(--text-white)'"
        :type="'small'"
        v-if="loading"
    />
    <slot></slot>
  </button>
</template>

<script setup>
import SpinnerComponent from "@/components/new/buttons/SpinnerComponent";
import { button } from "@/composables/button";
import { defineProps, useSlots } from 'vue';

defineProps(['disabled', 'loading']);

const { hasSvg } = button(useSlots());
</script>

<style scoped>
  button{
    background-color: var(--error);
    border: 1px solid var(--error);
    color: var(--text-white);
  }
</style>