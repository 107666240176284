<template>
  <article class="modal__bg" @click="closeModal">
    <section class="modal__container-md" @click.stop>
      <h2 class="tp-head-responsive">{{ title }}</h2>
      <p class="tp-body-responsive">{{ description }}</p>
      <ButtonPrimary type="button" @click="closeModal">Entendido</ButtonPrimary>
    </section>
  </article>
</template>

<script setup>
import { defineEmits, defineProps } from "vue"
import ButtonPrimary from "@/components/new/buttons/ButtonPrimary";

defineProps(['title', 'description'])
const emits = defineEmits(['close_modal_confirmation']);

function closeModal(){
  emits('close_modal_confirmation');
}
</script>

<style scoped>
h2,
p{
  text-align: center;
}
</style>