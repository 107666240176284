<template>
  <div class="vista_pedidos_tab_2">
    <div class="pedidos_vacios" v-if="this.pedidos.length===0">
      <p class="tp-body-responsive">{{ $t('perfilmispedidostab2sp.txt_historial_vacio') }}</p>
    </div>
    <div class="pedidos_cards"
          v-if="!selected_historic_order"
    >
      <ItemOrder
          v-for="(item,index) in pedidos"
          :key="index"
          :item="item"
          mensaje="Ver detalle"
          @viewOrder="showDetail(item)"
          @rateOrder="qualifyOrder(item)"
      ></ItemOrder>
    </div>

      <OrderInfoComponent
          v-if="selected_historic_order"
          :active_order="order_selected"
          :from_tracking=false
          :allow_back=true
          :key="order_selected"
          @close="selected_historic_order=null"
      >
      </OrderInfoComponent>

    <transition name="slide-fade">
      <ModalQualify
          v-if="showModalQualify"
          :order="order"
          @close="showModalQualify=false">
      </ModalQualify>
    </transition>

  </div>
</template>

<script>
import {defineAsyncComponent} from "vue";
import OrderInfoComponent from "@/components/OrderInfoComponent";
import ModalQualify from "@/components/MODALS/ModalQualify";

export default {
  props: ['orders'],
  components: {
    ModalQualify,
    OrderInfoComponent,
    ItemOrder: defineAsyncComponent(() => import("./PerfilMisPedidosItem")),
  },
  data () {
    return {
      pedidos: [],
      order_selected: [],
      selected_historic_order: false,
      showModalQualify: false,
      order: 0,
    }
  },
  mounted () {
    this.getHistoryOrders()
  },
  methods: {
    qualifyOrder(id) {
      this.order = id
      this.showModalQualify = true
    },
    showDetail (item) {
      this.order_selected = item
      this.selected_historic_order = true
    },
    getHistoryOrders () {
      this.pedidos = this.orders.filter(order => order.status === '4')
    },
  }
}
</script>

<style scoped>
  .pedidos_vacios{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    width: 100%;
    border: 1px dashed var(--border-gray);
    border-radius: 8px;
    padding: 10px;
    color: var(--text-black);
    transition: all .3s linear;
    cursor: pointer;
  }

  .pedidos_vacios p{
    text-align: center;
  }

  .pedidos_cards{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 14px;
  }

  @media screen and (min-width: 600px){
    .pedidos_cards{
      gap: 24px;
    }
  }

  @media screen and (min-width: 1240px){
    .pedidos_vacios{
      margin-top: 60px;
      width: 300px;
      margin-inline: auto;
    }
  }

</style>