<template>
  <div class="modal__bg">
    <div class="modal__container-md">
      <img
          src="@/assets/user/icon_campana.svg"
          role="presentation"
          alt=""
      >
      <h2 class="tp-head-responsive">{{ $t('modallocalschedules.txt_local_cerrado_nuestros_horarios_son') }}</h2>
      <ul class="submenu">
        <li
            class="tp-body-responsive"
            v-for="(horario, index) in this.orderLocal.day_list"
            :key="`h__${index}`"
        >
          {{getDayName(index)}}:
          <p class="tp-body-responsive" v-if="horario.length === 0">{{ $t('cobertura.txt_cerrado') }}</p>
          <p class="tp-body-responsive"
              v-for="(hora, index_new) in horario"
              :key="`hora__${index_new}`"
          >
            {{getDayTime(hora)}}
          </p>
        </li>
      </ul>
      <div class="modal__buttons" v-if="ok.length > 0">
        <ButtonPrimary type="button" @click="clickOk">{{ok}}</ButtonPrimary>
        <ButtonTexto type="button" @click="$emit('no')">{{no}}</ButtonTexto>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import moment from "moment";
import ButtonTexto from "@/components/new/buttons/ButtonTexto";
import ButtonPrimary from "@/components/new/buttons/ButtonPrimary";

export default {
  name: "ModalLocalSchedules",
  emits: ['sendOrder', 'ok', 'no'],
  props: ['ok', 'no', 'message', 'sendOrder'],
  components: {
    ButtonPrimary,
    ButtonTexto
  },
  data() {
    return {

    }
  },
  methods: {
    clickOk () {
      if (this.sendOrder) {
        this.$emit('sendOrder')
      } else {
        this.$emit('ok')
      }
    },

    getDayName(position) {
      return this.diasDeLaSemana[position]
    },
    getDayTime(object) {
      return `${moment(object.time_init, 'HH:mm:ss').format('hh:mm a')} - ${moment(object.time_end, 'HH:mm:ss').format('hh:mm a')}`
    },
  },

  computed: {
    ...mapState(["orderLocal", 'diasDeLaSemana'])
  }

}
</script>

<style scoped>
.modal__container-md{
  overflow: visible;
}

h2{
  text-align: center;
}

img{
  position: absolute;
  top: -15px;
  right: -15px;
}

.submenu{
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.submenu li{
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 14px;
  font-weight: var(--medium);
}

.submenu p{
  text-align: right;
  font-weight: var(--regular);
}
</style>