<template>
  <section class="plato">
    <div class="plato__image" :style="backgroundPlaceholderItem(product)" >
    </div>
    <div class="plato__information">
      <div class="plato__text">
        <h3>{{product.name}}</h3>
        <p>{{product.description}}</p>
      </div>
      <div class="plato__prices-btn">
        <div class="plato__prices">
          <p class="discount" v-if="product.has_discount">S/.{{product.promo_price}}</p>
          <p class="anterior" v-if="product.has_discount">S/.{{product.price.toFixed(2)}}</p>
          <p v-else>S/.{{product.price.toFixed(2)}}</p>
        </div>
      </div>
    </div>
    <div class="plato__discount" v-if="product.has_discount">
      <p>{{parseFloat((parseFloat(product.promo_price) - parseFloat(product.price))/(parseFloat(product.price))*100).toFixed(0)}}%</p>
    </div>
  </section>
</template>

<script>

export default {
  name: 'ProductoCarta',
  components: {},
  props: ['product'],
  methods: {

    backgroundPlaceholderItem() {
      let url;
      try {
        // eslint-disable-next-line no-unused-vars
        url = new URL(this.product.img)
        if (!this.product.is_available) {
          return {background:`url(${this.product.img}) center center`,'background-size':'cover', '-webkit-filter': 'grayscale(1)', 'filter': 'grayscale(1)'}
        }
        return {background:`url(${this.product.img}) center center`,'background-size':'cover' }
      } catch (error) {
        if (!this.product.is_available) {
          return {background:`url(${process.env.VUE_APP_IMG_PLACEHOLDER}) center center`,'background-size':'cover', '-webkit-filter': 'grayscale(1)', 'filter': 'grayscale(1)'}
        }
        return {background:`url(${process.env.VUE_APP_IMG_PLACEHOLDER}) center center`,'background-size':'cover' }
      }
    },
  }
}
</script>

<style scoped>
  .plato{
    position: relative;
    display: flex;
    border-radius: 8px;
    box-shadow: 0 0 4px rgba(41, 41, 41, .2);
    overflow: hidden;
    background: var(--text-white);
    cursor: pointer;
    height: 115px;
    padding: 8px;
    width: 100%;
    transition: background .3s linear;
  }

  .plato__image{
    width: 100px;
    min-width: 100px;
    height: 100%;
    border-radius: 8px;
    -o-object-fit: cover;
    object-fit: cover;
    display: block
  }

  .plato__information {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: -moz-max-content;
    padding: 0 0 0 10px;
    z-index: 1;
  }

  .plato__information::after,
  .plato__information::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition: all .3s linear;
    z-index: 0;
  }


  .plato__information::after {
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 40%, rgba(255, 255, 255, 0) 70%);
    opacity: 1;
  }

  .plato__information::before {
    background: linear-gradient(0deg, rgba(var(--secundary-50-rgb), 1) 40%, rgba(255, 255, 255, 0) 70%);
    opacity: 0;
  }

  .plato__information p{
    font-size: var(--fs-body-small);
    font-weight: var(--regular);
    letter-spacing: 0.4px;
    line-height: 16px;
    margin-top: 4px;
  }

  .plato__text{
    position: relative;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    height: 100%;
  }

  .plato__text h3{
    font-size: var(--fs-title-small);
    letter-spacing: 0.1px;
    font-weight: var(--semibold);
    line-height: 20px;
  }

  .plato__prices-btn{
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: auto;
  }

  .plato__prices-btn button{ display: none; }

  .plato__prices{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: auto;
  }

  .plato__prices p{
    color: var(--text-black);
    font-size: var(--fs-title-small);
    font-weight: var(--semibold);
    letter-spacing: 0.1px;
    line-height: 20px;
    text-decoration: none
  }

  .plato__prices .anterior{
    color: var(--border-gray);
    font-size: var(--fs-body-small);
    font-weight: var(--regular);
    letter-spacing: 0.4px;
    line-height: 16px;
    margin: 0;
    text-decoration: line-through
  }

  .plato__prices .discount{
    color: var(--text-black);
    font-size: var(--fs-title-small);
    font-weight: var(--semibold);
    letter-spacing: 0.1px;
    line-height: 20px;
    text-decoration: none;
    margin-top: 0;
  }

  .plato__discount{
    position: absolute;
    top: 5px;
    left: 0;
    padding: 5px 10px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: var(--primary);
  }

  .plato__discount p{
    color: var(--text-white);
    font-size: var(--fs-14);
    font-weight: var(--semibold);
  }

  @media screen and (min-width: 600px){
    .plato{
      height: 105px;
    }

    .plato__image{
      width: 78px;
      min-width: 78px;
    }

    .plato__information::after {
      background: linear-gradient(0deg, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0) 70%);
    }

    .plato__information::before {
      background: linear-gradient(0deg, rgba(var(--secundary-50-rgb), 1) 20%, rgba(255, 255, 255, 0) 70%);
    }

    .plato__text p{
      font-size: var(--fs-body-medium);
      line-height: 20px;
      letter-spacing: 0.25px;
    }
  }

  @media screen and (min-width: 905px){
    .plato{
      height: 140px;
      padding: 0;
    }

    .plato:hover{
      background: var(--secundary-50);
    }

    .plato__image{
      width: 140px;
      min-width: 140px;
      border-radius: 8px 0 0 8px;
    }

    .plato__information{
      padding: 8px 16px;
    }

    .plato__information::after {
      background: linear-gradient(0deg, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0) 70%);
    }

    .plato__information::before {
      background: linear-gradient(0deg, rgba(var(--secundary-50-rgb), 1) 30%, rgba(255, 255, 255, 0) 70%);
    }

    .plato:hover .plato__information::after {
      opacity: 0;
    }

    .plato:hover .plato__information::before {
      opacity: 1;
      z-index: 2;
    }

    .plato__text h3{
      font-size: var(--fs-title-medium);
      line-height: 24px;
      letter-spacing: 0.15px;
    }

    .plato__text p{
      margin-top: 6px;
      font-size: var(--fs-body-medium);
      line-height: 20px;
      letter-spacing: 0.25px;
    }

    .plato__prices p,
    .plato__prices .discount{
      font-size: var(--fs-title-medium);
      letter-spacing: 0.15px;
      line-height: 24px;
    }
  }

  @media screen and (min-width: 1240px){
    .plato{
      height: 200px;
      padding: 0;
    }

    .plato__image{
      width: 200px;
      min-width: 200px;
    }

    .plato__text {
      -webkit-line-clamp: 5;
    }

    .plato__information{
      padding: 12px 16px;
    }

    .plato__prices{
      margin-left: initial;
    }

    .plato__discount{
      top: 15px;
    }

    .plato__discount p{
      font-size: var(--fs-16);
    }
  }

  @media screen and (min-width: 1440px) {
    .plato__text h3{
      font-size: var(--fs-title-large);
      line-height: 28px;
      letter-spacing: 0;
    }

    .plato__text p{
      margin-top: 10px;
      font-size: var(--fs-body-large);
      line-height: 24px;
      letter-spacing: 0.5px;
    }

    .plato__prices p,
    .plato__prices .discount{
      font-size: var(--fs-title-large);
      line-height: 28px;
      letter-spacing: 0;
    }

    .plato__discount p{
      font-size: var(--fs-18);
    }
  }
  </style>