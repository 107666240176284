<template>
  <tbody>
    <tr v-if="screenWidth >= 905">
      <td>
        <div class="img">
          <img :src="backgroundPlaceholderItem(item)" alt="{{ item.name }}">
        </div>
        <div class="item__paragraph">
          <p class="item-name tp-subtitle-responsive">{{ item.product_name }}</p>
          <ul v-if="item.product_size_ids.length > 0">
            <li
                class="tp-body-responsive"
                v-for="(name,index) in item.product_size_names"
                :key="index"
            >
              {{name}}
            </li>
          </ul>
          <div class="item__paragraph" v-if="item.product_sizes2_ids.length > 0 || item.product_sizes3_ids.length > 0 || item.product_sizes4_ids.length > 0">
            <p class="item-name tp-subtitle-responsive">Opciones:</p>
            <ul v-if="item.product_sizes2_ids.length > 0">
              <li
                  class="tp-body-responsive"
                  v-for="(name,index) in item.product_sizes2_names"
                  :key="index"
              >
                {{name}}
              </li>
            </ul>
            <ul v-if="item.product_sizes3_ids.length > 0">
              <li
                  class="tp-body-responsive"
                  v-for="(name,index) in item.product_sizes3_names"
                  :key="index"
              >
                {{name}}
              </li>
            </ul>
            <ul v-if="item.product_sizes4_ids.length > 0">
              <li
                  class="tp-body-responsive"
                  v-for="(name,index) in item.product_sizes4_names"
                  :key="index"
              >
                {{name}}
              </li>
            </ul>
          </div>
          <div class="item__paragraph" v-if="item.ingredients_add.length > 0">
            <p class="item-name tp-subtitle-responsive">Extras:</p>
            <ul>
              <li
                  class="tp-body-responsive"
                  v-for="(name,index) in item.ingredients_add_names"
                  :key="index"
              >
                {{name}}
              </li>
            </ul>
          </div>
          <p class="tp-body-responsive" v-if="item.item_discount > 0" style="color: var(--satisfying); margin-top: 10px;">
            {{ $t('itemproductocarrito.txt_ahorraste') }} S/ {{(parseFloat(item.item_discount)*item.cant).toFixed(2)}}
          </p>
        </div>
      </td>
      <td class="cantidad-td">
        <p class="cantidad tp-body-responsive">{{ $t('itemproductopagar.txt_cantidad') }}</p>
        <p class="tp-body-responsive">{{item.cant}}</p>
      </td>
      <td>
        <p class="price tp-subtitle-responsive">
          S/ {{getTotal().toFixed(2)}}
        </p>
      </td>
    </tr>
    <section class="itemproduct" v-if="screenWidth < 905">
      <img :src="backgroundPlaceholderItem(item)" alt="{{ item.name }}">
      <div class="productInformation">
        <p class="productInformation__paragraph tp-subtitle-responsive">{{ item.product_name }}</p>
        <ul v-if="item.product_size_ids.length > 0">
          <li
              class="tp-body-responsive"
              v-for="(name,index) in item.product_size_names"
              :key="index"
          >
            {{name}}
          </li>
        </ul>
        <div v-if="item.product_sizes2_ids.length > 0 || item.product_sizes3_ids.length > 0 || item.product_sizes4_ids.length > 0">
          <p class="productInformation__paragraph tp-subtitle-responsive">Opciones:</p>
          <ul v-if="item.product_sizes2_ids.length > 0">
            <li
                class="tp-body-responsive"
                v-for="(name,index) in item.product_sizes2_names"
                :key="index"
            >
              {{name}}
            </li>
          </ul>
          <ul v-if="item.product_sizes3_ids.length > 0">
            <li
                class="tp-body-responsive"
                v-for="(name,index) in item.product_sizes3_names"
                :key="index"
            >
              {{name}}
            </li>
          </ul>
          <ul v-if="item.product_sizes4_ids.length > 0">
            <li
                class="tp-body-responsive"
                v-for="(name,index) in item.product_sizes4_names"
                :key="index"
            >
              {{name}}
            </li>
          </ul>
        </div>
        <div v-if="item.ingredients_add.length > 0">
          <p class="productInformation__paragraph tp-subtitle-responsive">Extras:</p>
          <ul>
            <li
                class="tp-body-responsive"
                v-for="(name,index) in item.ingredients_add_names"
                :key="index"
            >
              {{name}}
            </li>
          </ul>
        </div>
        <p class="tp-body-responsive" v-if="item.item_discount > 0" style="color: var(--satisfying)">
          {{ $t('itemproductocarrito.txt_ahorraste') }} S/ {{(parseFloat(item.item_discount)*item.cant).toFixed(2)}}
        </p>
        <p class="productInformation__paragraph-price tp-subtitle-responsive">{{item.cant}} x  S/ {{getTotal().toFixed(2)}}</p>
      </div>
    </section>
  </tbody>
</template>

<script>
export default {
  props: ['item',],
  computed: {

  },
  data() {
    return {
      screenWidth: window.innerWidth,
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
    getTotal () {
      let suma = 0
      suma += this.item.price
      suma += this.item.extras
      suma -= this.item.item_discount
      suma = suma * this.item.cant
      return suma
    },
    backgroundPlaceholderItem(product) {
      let url;
      try {
        // eslint-disable-next-line no-unused-vars
        url = new URL(product.product_img)
        return product.product_img
      } catch (error) {
        return process.env.VUE_APP_IMG_PLACEHOLDER
      }
    },
  },

}
</script>

<style scoped>
tbody:nth-child(odd){
  background-color: rgba(223, 230, 236, .4);
  border-radius: 16px;
}

.itemproduct{
  display: flex;
  gap: 14px;
  padding: 10px;
}

.itemproduct img{
  display: block;
  width: 30%;
  max-width: 72px;
  min-width: 72px;
  min-height: 72px;
  align-self: center;
}

.productInformation ul{
  margin: 4px 0 4px 4px;
  padding-left: 20px;
}

.productInformation ul li{
  list-style: initial;
}

.productInformation__paragraph-price{
  margin-top: 10px;
}

@media only screen and (min-width: 905px){
  .table thead{
    border-bottom: 1px solid var(--border-gray);
  }

  table th,
  tbody td{ padding: 20px 10px; }

  .table th,
  .table td {
    white-space: initial;
  }

  .item__paragraph ul{ padding-left: 20px; }

  .cantidad-td{
    text-align: center;
  }

  .price{
    text-align: right;
  }

  .img{ display: none; }

  .item__paragraph ul{
    padding-left: 30px;
  }

  .item__paragraph li{
    list-style: initial;
  }

  tbody td:first-child{
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

@media screen and (min-width: 1240px){
  .img{
    display: block;
    width: 80px;
    height: 80px;
    border-radius: 8px;
    overflow: hidden;
  }
}

@media only screen and (min-width: 1400px){
  .img{
    width: 90px;
    height: 90px;
  }
}
</style>