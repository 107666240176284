<template>
  <div class="modal__bg" @click="closeModal">
    <div class="qualify__container" @click.stop>
      <div class="container">
        <div class="qualify__close"
             @click="closeModal">
          <svg class="icon-close" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.82505 13L12.725 17.9C12.925 18.1 13.0209 18.3334 13.0125 18.6C13.0042 18.8667 12.9 19.1 12.7 19.3C12.5 19.4834 12.2667 19.5792 12 19.5875C11.7334 19.5959 11.5 19.5 11.3 19.3L4.70005 12.7C4.60005 12.6 4.52922 12.4917 4.48755 12.375C4.44588 12.2584 4.42505 12.1334 4.42505 12C4.42505 11.8667 4.44588 11.7417 4.48755 11.625C4.52922 11.5084 4.60005 11.4 4.70005 11.3L11.3 4.70005C11.4834 4.51672 11.7125 4.42505 11.9875 4.42505C12.2625 4.42505 12.5 4.51672 12.7 4.70005C12.9 4.90005 13 5.13755 13 5.41255C13 5.68755 12.9 5.92505 12.7 6.12505L7.82505 11H19C19.2834 11 19.5209 11.0959 19.7125 11.2875C19.9042 11.4792 20 11.7167 20 12C20 12.2834 19.9042 12.5209 19.7125 12.7125C19.5209 12.9042 19.2834 13 19 13H7.82505Z" fill="currentColor"/>
          </svg>
          <p>{{ $t('modalqualify.txt_salir_de_la_calificacion') }}</p>
        </div>
        <div class="qualify__titles">
          <h3 class="qualify__title">{{ $t('modalqualify.txt_encuesta_de_satisfaccion') }} #{{order.id}}</h3>
        </div>
        <div class="container__animation">
          <transition name="slide-fade">
            <div class="two" id="two" v-if="currentStep === '1/4'">
              <h3>1/3</h3>
              <h4 v-if="order_details && order_details.length > 0">{{ $t('modalqualify.txt_como_calificarias_tu_pedido') }} {{ getBusinessName() }}?</h4>
              <div class="starts" @click="showChips">
                <div class="start">
                  <div class="vuelottie">
                    <Vue3Lottie
                        @onAnimationLoaded="loadedLottieStar(1)"
                        ref="oneStarInitialRef"
                        :animationData=oneStarAnimation
                        :height="50"
                        :width="50"
                        style="margin:-10px 0"
                        :loop="0"
                        :autoPlay=false
                        @click="ratedStars(1)"
                    />
                    <span>1</span>
                  </div>
                  <div class="vuelottie">
                    <Vue3Lottie
                        @onAnimationLoaded="loadedLottieStar(2)"
                        ref="twoStarInitialRef"
                        :animationData=twoStarAnimation
                        :height="50"
                        :width="50"
                        style="margin:-10px 0"
                        :loop="0"
                        :autoPlay=false
                        @click="ratedStars(2)"
                    />
                    <span>2</span>
                  </div>
                  <div class="vuelottie">
                    <Vue3Lottie
                        @onAnimationLoaded="loadedLottieStar(3)"
                        ref="threeStarInitialRef"
                        :animationData=threeStarAnimation
                        :height="50"
                        :width="50"
                        style="margin:-10px 0"
                        :loop="0"
                        :autoPlay=false
                        @click="ratedStars(3)"
                    />
                    <span>3</span>
                  </div>
                  <div class="vuelottie">
                    <Vue3Lottie
                        @onAnimationLoaded="loadedLottieStar(4)"
                        ref="fourStarInitialRef"
                        :animationData=fourStarAnimation
                        :height="50"
                        :width="50"
                        style="margin:-10px 0"
                        :loop="0"
                        :autoPlay=false
                        @click="ratedStars(4)"
                    />
                    <span>4</span>
                  </div>
                  <div class="vuelottie">
                    <Vue3Lottie
                        @onAnimationLoaded="loadedLottieStar(5)"
                        ref="fiveStarInitialRef"
                        :animationData=fiveStarAnimation
                        :height="50"
                        :width="50"
                        style="margin:-10px 0"
                        :loop="0"
                        :autoPlay=false
                        @click="ratedStars(5)"
                    />
                    <span>5</span>
                  </div>
                </div>
                <div class="start-p">
                  <p>{{ $t('modalqualify.txt_muy_mala') }}</p>
                  <p>{{ $t('modalqualify.txt_excelente') }}</p>
                </div>
              </div>
              <div class="chips__wrapper-title" :class="{'chips__wrapper-title-active' : chipsInformation}" v-show="chipsInformation">
                <h4 v-html="title_qualify_modal"></h4>
                <div class="chips__wrapper">
                  <DarkSelectFilterChip
                      :options_filterChips="ratingTextOptions"
                      v-model="payload.review_comments"
                      :valueObj="null"
                      :name="'reviewChipSelect'"
                  ></DarkSelectFilterChip>
                </div>
                <p class="addcoment"
                   v-show="!showComent"
                   @click="showComent = true">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 13H6C5.71667 13 5.47917 12.9042 5.2875 12.7125C5.09583 12.5208 5 12.2833 5 12C5 11.7167 5.09583 11.4792 5.2875 11.2875C5.47917 11.0958 5.71667 11 6 11H11V6C11 5.71667 11.0958 5.47917 11.2875 5.2875C11.4792 5.09583 11.7167 5 12 5C12.2833 5 12.5208 5.09583 12.7125 5.2875C12.9042 5.47917 13 5.71667 13 6V11H18C18.2833 11 18.5208 11.0958 18.7125 11.2875C18.9042 11.4792 19 11.7167 19 12C19 12.2833 18.9042 12.5208 18.7125 12.7125C18.5208 12.9042 18.2833 13 18 13H13V18C13 18.2833 12.9042 18.5208 12.7125 18.7125C12.5208 18.9042 12.2833 19 12 19C11.7167 19 11.4792 18.9042 11.2875 18.7125C11.0958 18.5208 11 18.2833 11 18V13Z" fill="currentColor"/>
                  </svg>
                  {{ $t('modalqualify.txt_agregar_comentario') }}
                </p>
              </div>
              <InputComponent
                  v-show="showComent"
                  :disabled=false
                  :placeholder="$t('placeholder.txt_comentarios_adicionales')"
                  :type="'text'"
                  :required=false
                  :bg_color="'var(--text-white)'"
                  v-model=comentario
              >
              </InputComponent>
            </div>
          </transition>
          <transition name="slide-fade">
            <div class="three" id="three" v-if="currentStep === '2/4'">
              <h3>2/3</h3>
              <h4>{{ $t('modalqualify.txt_que_tanto_te_gustaron_estos_platos') }}</h4>
              <div class="platos__wrapper">
                <div class="platos" v-for="(detail, index) in order_details" :key="index">
                  <p class="platos-icon">
                    {{ detail.product }}
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path v-if="!detail.show_comments" @click="detail.show_comments = true" d="M11 13H6C5.71667 13 5.47917 12.9042 5.2875 12.7125C5.09583 12.5208 5 12.2833 5 12C5 11.7167 5.09583 11.4792 5.2875 11.2875C5.47917 11.0958 5.71667 11 6 11H11V6C11 5.71667 11.0958 5.47917 11.2875 5.2875C11.4792 5.09583 11.7167 5 12 5C12.2833 5 12.5208 5.09583 12.7125 5.2875C12.9042 5.47917 13 5.71667 13 6V11H18C18.2833 11 18.5208 11.0958 18.7125 11.2875C18.9042 11.4792 19 11.7167 19 12C19 12.2833 18.9042 12.5208 18.7125 12.7125C18.5208 12.9042 18.2833 13 18 13H13V18C13 18.2833 12.9042 18.5208 12.7125 18.7125C12.5208 18.9042 12.2833 19 12 19C11.7167 19 11.4792 18.9042 11.2875 18.7125C11.0958 18.5208 11 18.2833 11 18V13Z" fill="currentColor"/>
                      <path v-else @click="detail.show_comments = false" d="M6 13C5.71667 13 5.47917 12.9042 5.2875 12.7125C5.09583 12.5208 5 12.2833 5 12C5 11.7167 5.09583 11.4792 5.2875 11.2875C5.47917 11.0958 5.71667 11 6 11H18C18.2833 11 18.5208 11.0958 18.7125 11.2875C18.9042 11.4792 19 11.7167 19 12C19 12.2833 18.9042 12.5208 18.7125 12.7125C18.5208 12.9042 18.2833 13 18 13H6Z" fill="currentColor"/>
                    </svg>
                  </p>
                  <div class="starts-2">
                    <div class="vuelottie">
                      <Vue3Lottie
                          @onAnimationLoaded="loadedProductLottieStar(index, 1)"
                          :ref="`${detail.id}_oneStar`"
                          :animationData=oneStarAnimation
                          :height="50"
                          :width="50"
                          style="margin:-10px 0"
                          :loop="0"
                          :autoPlay=false
                          @click="ratedProductStars(index, 1)"
                      />
                      <span>1</span>
                    </div>
                    <div class="vuelottie">
                      <Vue3Lottie
                          @onAnimationLoaded="loadedProductLottieStar(index,2)"
                          :ref="`${detail.id}_twoStar`"
                          :animationData=twoStarAnimation
                          :height="50"
                          :width="50"
                          style="margin:-10px 0"
                          :loop="0"
                          :autoPlay=false
                          @click="ratedProductStars(index, 2)"
                      />
                      <span>2</span>
                    </div>
                    <div class="vuelottie">
                      <Vue3Lottie
                          @onAnimationLoaded="loadedProductLottieStar(index, 3)"
                          :ref="`${detail.id}_threeStar`"
                          :animationData=threeStarAnimation
                          :height="50"
                          :width="50"
                          style="margin:-10px 0"
                          :loop="0"
                          :autoPlay=false
                          @click="ratedProductStars(index, 3)"
                      />
                      <span>3</span>
                    </div>
                    <div class="vuelottie">
                      <Vue3Lottie
                          @onAnimationLoaded="loadedProductLottieStar(index, 4)"
                          :ref="`${detail.id}_fourStar`"
                          :animationData=fourStarAnimation
                          :height="50"
                          :width="50"
                          style="margin:-10px 0"
                          :loop="0"
                          :autoPlay=false
                          @click="ratedProductStars(index,4)"
                      />
                      <span>4</span>
                    </div>
                    <div class="vuelottie">
                      <Vue3Lottie
                          @onAnimationLoaded="loadedProductLottieStar(index, 5)"
                          :ref="`${detail.id}_fiveStar`"
                          :animationData=fiveStarAnimation
                          :height="50"
                          :width="50"
                          style="margin:-10px 0"
                          :loop="0"
                          :autoPlay=false
                          @click="ratedProductStars(index,5)"
                      />
                      <span>5</span>
                    </div>
                  </div>
                  <div class="coment-input">
                    <p
                        v-show="!detail.show_comments"
                        @click="detail.show_comments = true">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 13H6C5.71667 13 5.47917 12.9042 5.2875 12.7125C5.09583 12.5208 5 12.2833 5 12C5 11.7167 5.09583 11.4792 5.2875 11.2875C5.47917 11.0958 5.71667 11 6 11H11V6C11 5.71667 11.0958 5.47917 11.2875 5.2875C11.4792 5.09583 11.7167 5 12 5C12.2833 5 12.5208 5.09583 12.7125 5.2875C12.9042 5.47917 13 5.71667 13 6V11H18C18.2833 11 18.5208 11.0958 18.7125 11.2875C18.9042 11.4792 19 11.7167 19 12C19 12.2833 18.9042 12.5208 18.7125 12.7125C18.5208 12.9042 18.2833 13 18 13H13V18C13 18.2833 12.9042 18.5208 12.7125 18.7125C12.5208 18.9042 12.2833 19 12 19C11.7167 19 11.4792 18.9042 11.2875 18.7125C11.0958 18.5208 11 18.2833 11 18V13Z" fill="currentColor"/>
                      </svg>
                      {{ $t('modalqualify.txt_comentario') }}
                    </p>
                    <InputComponent
                        v-show="detail.show_comments"
                        :disabled="false"
                        :placeholder="$t('placeholder.txt_comentarios_adicionales')"
                        :type="'text'"
                        :required="false"
                        :bg_color="'var(--text-white)'"
                        v-model="detail.comments"
                    >
                    </InputComponent>
                  </div>
                </div>

              </div>
            </div>
          </transition>
          <transition name="slide-fade">
            <div class="four" id="four" v-if="currentStep === '3/4'">
              <h3>3/3</h3>
              <h4 v-if="order_details && order_details.length > 0">{{ $t('modalqualify.txt_recomendarias') }} {{getBusinessName()}}?</h4>
              <div class="starts">
                <div class="start">
                  <div class="vuelottie">
                    <Vue3Lottie
                        @onAnimationLoaded="loadedLottieStarRecomendation(1)"
                        ref="oneStarRecomendation"
                        :animationData=oneStarAnimation
                        :height="50"
                        :width="50"
                        style="margin:-10px 0"
                        :loop="0"
                        :autoPlay=false
                        @click="ratedRecomendationStars(1)"
                    />
                    <span>1</span>
                  </div>
                  <div class="vuelottie">
                    <Vue3Lottie
                        @onAnimationLoaded="loadedLottieStarRecomendation(2)"
                        ref="twoStarRecomendation"
                        :animationData=twoStarAnimation
                        :height="50"
                        :width="50"
                        style="margin:-10px 0"
                        :loop="0"
                        :autoPlay=false
                        @click="ratedRecomendationStars(2)"
                    />
                    <span>2</span>
                  </div>
                  <div class="vuelottie">
                    <Vue3Lottie
                        @onAnimationLoaded="loadedLottieStarRecomendation(3)"
                        ref="threeStarRecomendation"
                        :animationData=threeStarAnimation
                        :height="50"
                        :width="50"
                        style="margin:-10px 0"
                        :loop="0"
                        :autoPlay=false
                        @click="ratedRecomendationStars(3)"
                    />
                    <span>3</span>
                  </div>
                  <div class="vuelottie">
                    <Vue3Lottie
                        @onAnimationLoaded="loadedLottieStarRecomendation(4)"
                        ref="fourStarRecomendation"
                        :animationData=fourStarAnimation
                        :height="50"
                        :width="50"
                        style="margin:-10px 0"
                        :loop="0"
                        :autoPlay=false
                        @click="ratedRecomendationStars(4)"
                    />
                    <span>4</span>
                  </div>
                  <div class="vuelottie">
                    <Vue3Lottie
                        @onAnimationLoaded="loadedLottieStarRecomendation(5)"
                        ref="fiveStarRecomendation"
                        :animationData=fiveStarAnimation
                        :height="50"
                        :width="50"
                        style="margin:-10px 0"
                        :loop="0"
                        :autoPlay=false
                        @click="ratedRecomendationStars(5)"
                    />
                    <span>5</span>
                  </div>
                </div>
                <div class="start-p">
                  <p>{{ $t('modalqualify.txt_nada_probable') }}</p>
                  <p>{{ $t('modalqualify.txt_muy_probable') }}</p>
                </div>
              </div>
            </div>
          </transition>
          <div class="five" v-if="currentStep === '4/4'">
            <svg width="225" height="157" viewBox="0 0 225 157" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M94.2927 120.493L112.605 109.447L130.918 120.638L126.122 99.7093L142.255 85.7567L121.035 83.8673L112.605 64.1011L104.176 83.7219L82.9562 85.6113L99.0889 99.7093L94.2927 120.493ZM112.605 123.109L88.4791 137.643C87.4133 138.321 86.299 138.612 85.1363 138.515C83.9736 138.418 82.9562 138.03 82.0842 137.352C81.2121 136.674 80.5339 135.826 80.0494 134.809C79.5649 133.791 79.468 132.653 79.7587 131.393L86.1537 103.924L64.7888 85.466C63.8198 84.5939 63.2143 83.6008 62.972 82.4865C62.7298 81.3723 62.8025 80.2822 63.19 79.2164C63.5776 78.1506 64.159 77.2785 64.9341 76.6003C65.7093 75.922 66.7751 75.486 68.1316 75.2922L96.3274 72.8215L107.228 46.951C107.712 45.7883 108.463 44.9163 109.481 44.3349C110.498 43.7536 111.54 43.4629 112.605 43.4629C113.671 43.4629 114.713 43.7536 115.73 44.3349C116.748 44.9163 117.499 45.7883 117.983 46.951L128.883 72.8215L157.079 75.2922C158.436 75.486 159.502 75.922 160.277 76.6003C161.052 77.2785 161.633 78.1506 162.021 79.2164C162.408 80.2822 162.481 81.3723 162.239 82.4865C161.997 83.6008 161.391 84.5939 160.422 85.466L139.057 103.924L145.452 131.393C145.743 132.653 145.646 133.791 145.161 134.809C144.677 135.826 143.999 136.674 143.127 137.352C142.255 138.03 141.237 138.418 140.075 138.515C138.912 138.612 137.798 138.321 136.732 137.643L112.605 123.109Z" :fill="'var(--primary)'"/>
              <path d="M201.093 69.3062L205.274 68.6627L207.783 72.0874L208.479 67.8978L212.48 66.5585L208.74 64.617L208.685 60.3669L205.667 63.3224L201.643 62.0722L203.534 65.8647L201.093 69.3062ZM204.247 71.1621L198.74 72.0065C198.494 72.0504 198.268 72.0198 198.063 71.9146C197.857 71.8095 197.7 71.662 197.592 71.4724C197.483 71.2827 197.423 71.0766 197.411 70.854C197.399 70.6315 197.467 70.4159 197.615 70.2073L200.85 65.6629L198.33 60.6792C198.218 60.4468 198.182 60.2196 198.222 59.9975C198.261 59.7754 198.357 59.5815 198.508 59.4157C198.659 59.2498 198.831 59.134 199.023 59.0682C199.216 59.0024 199.444 59.0028 199.707 59.0694L205.051 60.738L208.991 56.8249C209.167 56.6486 209.37 56.5455 209.6 56.5157C209.83 56.4859 210.042 56.511 210.237 56.5912C210.432 56.6713 210.601 56.8028 210.743 56.9857C210.885 57.1686 210.957 57.3846 210.958 57.6338L211.007 63.1865L215.979 65.7591C216.213 65.8965 216.375 66.0565 216.466 66.2388C216.557 66.4212 216.598 66.6245 216.588 66.8486C216.579 67.0728 216.51 67.2776 216.382 67.4633C216.254 67.6489 216.069 67.7851 215.826 67.8717L210.529 69.6417L209.633 75.1481C209.591 75.4004 209.488 75.6014 209.323 75.7511C209.158 75.9007 208.97 76.0048 208.759 76.0633C208.549 76.1218 208.334 76.1162 208.114 76.0465C207.894 75.9768 207.712 75.8398 207.568 75.6356L204.247 71.1621Z" :fill="'var(--primary)'"/>
              <path d="M23.9067 68.8253L19.7257 68.1818L17.2171 71.6064L16.5205 67.4168L12.5197 66.0776L16.2597 64.136L16.3153 59.8859L19.3331 62.8415L23.3572 61.5912L21.4661 65.3837L23.9067 68.8253ZM20.7532 70.6811L26.2601 71.5256C26.5061 71.5695 26.7318 71.5389 26.9372 71.4337C27.1427 71.3285 27.2996 71.1811 27.4082 70.9914C27.5167 70.8018 27.577 70.5956 27.5891 70.3731C27.6012 70.1505 27.5333 69.9349 27.3854 69.7264L24.1496 65.1819L26.67 60.1983C26.7817 59.9659 26.8177 59.7386 26.7783 59.5166C26.7388 59.2945 26.6435 59.1005 26.4924 58.9347C26.3414 58.7689 26.1694 58.653 25.9766 58.5873C25.7838 58.5215 25.556 58.5219 25.2933 58.5884L19.9491 60.257L16.0092 56.3439C15.8332 56.1676 15.6302 56.0646 15.4003 56.0347C15.1705 56.0049 14.9581 56.0301 14.7631 56.1102C14.5681 56.1904 14.3994 56.3219 14.257 56.5048C14.1146 56.6876 14.0428 56.9036 14.0416 57.1528L13.9931 62.7055L9.02062 65.2781C8.78702 65.4156 8.62483 65.5755 8.53403 65.7579C8.44323 65.9403 8.40246 66.1435 8.41171 66.3677C8.42097 66.5918 8.48965 66.7967 8.61777 66.9823C8.74589 67.168 8.93138 67.3041 9.17422 67.3908L14.4711 69.1608L15.3671 74.6671C15.4086 74.9194 15.512 75.1204 15.6771 75.2701C15.8423 75.4198 16.0301 75.5239 16.2407 75.5824C16.4512 75.6409 16.6665 75.6353 16.8865 75.5655C17.1065 75.4958 17.2885 75.3588 17.4324 75.1546L20.7532 70.6811Z" :fill="'var(--primary)'"/>
              <path d="M80.6774 21.6705L76.7963 19.9875L73.5011 22.6639L73.89 18.4347L70.3597 16.1244L74.4698 15.195L75.6016 11.0979L77.7711 14.7223L81.9808 14.5337L79.1895 17.7225L80.6774 21.6705ZM77.1562 22.6658L82.2689 24.8794C82.4957 24.9843 82.7218 25.0119 82.9472 24.9623C83.1726 24.9127 83.3618 24.8099 83.5149 24.654C83.668 24.498 83.7786 24.3139 83.8468 24.1017C83.9149 23.8895 83.9039 23.6638 83.8138 23.4245L81.8364 18.2079L85.5385 14.0265C85.7055 13.8301 85.798 13.6194 85.8161 13.3946C85.8343 13.1698 85.7913 12.958 85.6872 12.7593C85.5832 12.5605 85.4462 12.4049 85.2764 12.2923C85.1066 12.1798 84.8862 12.1224 84.6151 12.1202L79.0225 12.3786L76.204 7.59418C76.0784 7.379 75.9082 7.22783 75.6935 7.14066C75.4787 7.0535 75.2669 7.02396 75.0579 7.05204C74.849 7.08012 74.6524 7.16454 74.4683 7.30532C74.2842 7.44609 74.1599 7.63683 74.0956 7.87753L72.6402 13.2363L67.1778 14.4636C66.917 14.5373 66.7195 14.6508 66.5855 14.8042C66.4514 14.9576 66.3604 15.1439 66.3125 15.363C66.2646 15.5822 66.279 15.7978 66.3559 16.0099C66.4327 16.2219 66.5776 16.4007 66.7905 16.5461L71.4652 19.6017L70.9352 25.1553C70.9114 25.4098 70.9604 25.6305 71.0822 25.8172C71.204 26.0039 71.3593 26.1522 71.5481 26.2622C71.7369 26.3722 71.9466 26.4213 72.1771 26.4097C72.4076 26.3981 72.6184 26.3117 72.8094 26.1507L77.1562 22.6658Z" :fill="'var(--primary)'"/>
              <path d="M138.449 21.6705L142.33 19.9875L145.626 22.6639L145.237 18.4347L148.767 16.1244L144.657 15.195L143.525 11.0979L141.356 14.7223L137.146 14.5337L139.937 17.7225L138.449 21.6705ZM141.97 22.6658L136.858 24.8794C136.631 24.9843 136.405 25.0119 136.18 24.9623C135.954 24.9127 135.765 24.8099 135.612 24.654C135.459 24.498 135.348 24.3139 135.28 24.1017C135.212 23.8895 135.223 23.6638 135.313 23.4245L137.29 18.2079L133.588 14.0265C133.421 13.8301 133.329 13.6194 133.311 13.3946C133.292 13.1698 133.335 12.958 133.439 12.7593C133.544 12.5605 133.68 12.4049 133.85 12.2923C134.02 12.1798 134.241 12.1224 134.512 12.1202L140.104 12.3786L142.923 7.59418C143.048 7.379 143.218 7.22783 143.433 7.14066C143.648 7.0535 143.86 7.02396 144.069 7.05204C144.278 7.08012 144.474 7.16454 144.658 7.30532C144.843 7.44609 144.967 7.63683 145.031 7.87753L146.486 13.2363L151.949 14.4636C152.21 14.5373 152.407 14.6508 152.541 14.8042C152.675 14.9576 152.766 15.1439 152.814 15.363C152.862 15.5822 152.848 15.7978 152.771 16.0099C152.694 16.2219 152.549 16.4007 152.336 16.5461L147.662 19.6017L148.191 25.1553C148.215 25.4098 148.166 25.6305 148.045 25.8172C147.923 26.0039 147.767 26.1522 147.579 26.2622C147.39 26.3722 147.18 26.4213 146.95 26.4097C146.719 26.3981 146.508 26.3117 146.317 26.1507L141.97 22.6658Z" :fill="'var(--primary)'"/>
            </svg>
            <h3>{{ $t('modalqualify.txt_gracias_por_calificar') }}</h3>
            <p>{{ $t('modalqualify.txt_tu_calificacion_nos_ayudara') }}</p>
          </div>
        </div>
      </div>
      <div class="buttons" :class="{'button-active' : currentStep === '2/4'}">
        <ButtonTexto
            :type="'button'"
            class="buttontxt"
            :class="{'button-text' : currentStep === '2/4'}"
            v-if="currentStep === '2/4' || currentStep === '3/4'"
            @click="backNextStep"
        >
          {{ backBtnTxt() }}
        </ButtonTexto>
        <ButtonPrimary
            :type="'button'"
            :loading="loading"
            @click="showNextStep"
        >
          {{nextBtnTxt()}}
        </ButtonPrimary>
      </div>
    </div>
  </div>
</template>

<script>

import InputComponent from "@/components/new/inputs/InputComponent.vue";
import ButtonPrimary from "@/components/new/buttons/ButtonPrimary.vue";
import ButtonTexto from "../new/buttons/ButtonTexto.vue";
import {Vue3Lottie} from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import oneStarAnimation from '@/assets/animationViews/ratingAnimation.json';
import twoStarAnimation from '@/assets/animationViews/ratingAnimation.json';
import threeStarAnimation from '@/assets/animationViews/ratingAnimation.json';
import fourStarAnimation from '@/assets/animationViews/ratingAnimation.json';
import fiveStarAnimation from '@/assets/animationViews/ratingAnimation.json';
import OrderServices from "@/services/order-services";
import DarkSelectFilterChip from "@/components/new/buttons/DarkSelectFilterChip";

export default {
  name: 'ModalQualify',
  components: {DarkSelectFilterChip, ButtonPrimary, InputComponent, ButtonTexto, Vue3Lottie },
  props: ['order', 'from_tracking', 'product'],
  emits: ['close'],
  data () {
    return {
      loading: true,
      chipsInformation: false,
      comentario: '',
      ratingTextOptions: [
        this.$t('modalqualify.txt_eficiencia_de_la_web'),
        this.$t('modalqualify.txt_cantidad'),
        this.$t('modalqualify.txt_rapidez'),
        this.$t('modalqualify.txt_calidad_de_la_comida'),
        this.$t('modalqualify.txt_precio'),
        this.$t('modalqualify.txt_servicio_del_delivery'),
        this.$t('modalqualify.txt_buena_presentacion'),
        this.$t('modalqualify.txt_variedad')
      ],
      showComent: false,
      showComentProductos: false,
      showComentOrderDetails: false,
      title_qualify_modal: this.$t('modalqualify.txt_como_calificarias_tu_pedido'),
      currentStep: '1/4',
      comentarioProductos: '',
      oneStarAnimation,
      twoStarAnimation,
      threeStarAnimation,
      fourStarAnimation,
      fiveStarAnimation,
      oneStarRef: null,
      twoStarRef: null,
      threeStarRef: null,
      fourStarRef: null,
      fiveStarRef: null,
      delayInitialStarsAnimation: 200,
      animKey: 0,
      productKey: 0,
      recommendationKey: 0,
      payload: {
        order: 0,
        rating: 0,
        review_comments: [],
        recommendation_score: 0,
        details: []
      },
      order_details: [],
      oneStarRecomendation: null,
      twoStarRecomendation: null,
      threeStarRecomendation: null,
      fourStarRecomendation: null,
      fiveStarRecomendation: null,
    }
  },
  async mounted() {
    this.payload.order = this.order.id
    let response = await OrderServices.detailOrderWithQr({qr_code: this.order.code_qr})
    if (response.data.success) {
      this.order_details = response.data.data
      for (let i=0; i < this.order_details.length; i++) {
        this.order_details[i].oneStar = null
        this.order_details[i].twoStar = null
        this.order_details[i].threeStar = null
        this.order_details[i].fourStar = null
        this.order_details[i].fiveStar = null
        this.order_details[i].score = 0
        this.order_details[i].comments = ''
      }
      console.log('detalle modal qualify', this.order_details)
    }
    this.loading = false

  },
  methods: {
    getBusinessName() {
      return process.env.VUE_APP_BUSINESS_COMERCIAL_NAME
    },

    showChips(){
      this.chipsInformation = true;
    },

    checkData() {
      console.log("es", this.payload)
    },

    loadedLottieStar(number) {
      switch (number) {
        case 1:
          this.oneStarRef = this.$refs.oneStarInitialRef
          break
        case 2:
          this.twoStarRef = this.$refs.twoStarInitialRef
          break
        case 3:
          this.threeStarRef = this.$refs.threeStarInitialRef
          break
        case 4:
          this.fourStarRef = this.$refs.fourStarInitialRef
          break
        case 5:
          this.fiveStarRef = this.$refs.fiveStarInitialRef
          break
      }
    },

    loadedProductLottieStar(index, number) {
      switch (number) {
        case 1:
          this.order_details[index].oneStar = this.$refs[`${this.order_details[index].id}_oneStar`][0]
          break
        case 2:
          this.order_details[index].twoStar = this.$refs[`${this.order_details[index].id}_twoStar`][0]
          break
        case 3:
          this.order_details[index].threeStar = this.$refs[`${this.order_details[index].id}_threeStar`][0]
          break
        case 4:
          this.order_details[index].fourStar = this.$refs[`${this.order_details[index].id}_fourStar`][0]
          break
        case 5:
          this.order_details[index].fiveStar = this.$refs[`${this.order_details[index].id}_fiveStar`][0]
          break
      }
    },

    loadedLottieStarRecomendation(number) {

      switch (number) {
        case 1:
          this.oneStarRecomendation = this.$refs.oneStarRecomendation
          break
        case 2:
          this.twoStarRecomendation = this.$refs.twoStarRecomendation
          break
        case 3:
          this.threeStarRecomendation = this.$refs.threeStarRecomendation
          break
        case 4:
          this.fourStarRecomendation = this.$refs.fourStarRecomendation
          break
        case 5:
          this.fiveStarRecomendation = this.$refs.fiveStarRecomendation
          break
      }

    },

    ratedStars(number) {
      this.resetInitialStarsAnimations();

      const starRefs = [
        this.oneStarRef,
        this.twoStarRef,
        this.threeStarRef,
        this.fourStarRef,
        this.fiveStarRef,
      ];

      for (let i = 0; i < number; i++) {
        const delay = this.delayInitialStarsAnimation * i;
        let current_key = this.animKey
        setTimeout(() => {
          if (current_key === this.animKey) {
            starRefs[i].playSegments([0, 60], true);
          }
        }, delay);
      }
      let posibles_titulos = [
        this.$t('modalqualify.txt_como_podemos_mejorar'),
        this.$t('modalqualify.txt_como_podemos_mejorar'),
        this.$t('modalqualify.txt_como_podemos_mejorar'),
        this.$t('modalqualify.txt_aun_falta_una_estrella'),
        this.$t('modalqualify.txt_muchas_gracias_que_fue')
      ]
      this.title_qualify_modal = posibles_titulos[number-1]
      this.payload.rating = number
    },

    resetInitialStarsAnimations() {
      this.animKey += 1
      this.oneStarRef.goToAndStop(0, true);
      this.twoStarRef.goToAndStop(0, true);
      this.threeStarRef.goToAndStop(0, true);
      this.fourStarRef.goToAndStop(0, true);
      this.fiveStarRef.goToAndStop(0, true);
    },

    ratedProductStars(index, number) {
      this.resetProductStarsAnimation(index)
      const starRefs = [
        this.order_details[index].oneStar,
        this.order_details[index].twoStar,
        this.order_details[index].threeStar,
        this.order_details[index].fourStar,
        this.order_details[index].fiveStar,
      ];
      for (let i = 0; i < number; i++) {
        const delay = this.delayInitialStarsAnimation * i;
        let current_key = this.productKey
        setTimeout(() => {
          if (current_key === this.productKey) {
            starRefs[i].playSegments([0, 60], true);
          }
        }, delay);
      }
      this.order_details[index].score = number
    },

    ratedRecomendationStars(number) {
      this.resetRecomendationStars();

      const starRefs = [
        this.oneStarRecomendation,
        this.twoStarRecomendation,
        this.threeStarRecomendation,
        this.fourStarRecomendation,
        this.fiveStarRecomendation,
      ];

      for (let i = 0; i < number; i++) {
        const delay = this.delayInitialStarsAnimation * i;
        let current_key = this.recommendationKey
        setTimeout(() => {
          if (current_key === this.recommendationKey) {
            starRefs[i].playSegments([0, 60], true);
          }
        }, delay);
      }
      this.payload.recommendation_score = number
    },

    resetProductStarsAnimation(index) {
      this.productKey += 1
      this.order_details[index].oneStar.goToAndStop(0, true)
      this.order_details[index].twoStar.goToAndStop(0, true)
      this.order_details[index].threeStar.goToAndStop(0, true)
      this.order_details[index].fourStar.goToAndStop(0, true)
      this.order_details[index].fiveStar.goToAndStop(0, true)
    },

    resetRecomendationStars() {
      this.recommendationKey += 1
      this.oneStarRecomendation.goToAndStop(0, true);
      this.twoStarRecomendation.goToAndStop(0, true);
      this.threeStarRecomendation.goToAndStop(0, true);
      this.fourStarRecomendation.goToAndStop(0, true);
      this.fiveStarRecomendation.goToAndStop(0, true);
    },

    nextBtnTxt() {
      if (this.loading) {
        return this.$t('forgotpassword.txt_cargando_dat');
      }
      if (this.currentStep === '1/4'){
        console.log(`Ventana 1 escogio: ${this.payload.comments}`)
        return this.$t('buttons.txt_siguiente');
      }
      if (this.currentStep === '2/4'){
        console.log(`Ventana 2 escogio: ${this.payload.comments}`)
        return this.$t('buttons.txt_siguiente');
      }
      if (this.currentStep === '3/4'){
        console.log(`Ventana 3 escogio: ${this.payload.comments}`)
        return this.$t('buttons.txt_siguiente');
      }
      if (this.currentStep === '4/4'){
        console.log(`Ventana 4 escogio: ${this.payload.comments}`)
        return this.$t('buttons.txt_cerrar');
      }
    },
    backBtnTxt() {
      switch (this.currentStep) {
        case '2/4':
          return this.$t('buttons.txt_anterior');
        case '3/4':
          return this.$t('buttons.txt_anterior');
        case '4/4':
          return ''
      }
      return ''
    },
    async showNextStep() {
      if (this.payload.rating === 0) {
        this.$toast.error(this.$t('modalqualify.txt_por_favor_debe_seleccionar_alguna'))
        return
      }
      if (!this.payload.review_comments.includes(this.comentario) && this.comentario.length > 0) {
        this.payload.review_comments.push(this.comentario)
      }
      if (this.payload.review_comments.length === 0) {
        this.$toast.error(this.$t('modalqualify.txt_por_favor_seleccione_alguna_opcion_o_ingrese'))
        return
      }

      if (this.currentStep === '1/4'){
        let two = document.getElementById('two');
        two.style.position = 'absolute';
        this.currentStep = '2/4'
        return
      }

      if (this.currentStep === '2/4'){
        this.payload.details = []
        for (let i=0; i < this.order_details.length; i++) {
          if (this.order_details[i].score === 0) {
            this.$toast.error(this.$t('modalqualify.txt_por_favor_califique_el_producto', { productName: this.order_details[i].product_name }));
            return
          }
          this.payload.details.push( {
            id: this.order_details[i].product_id,
            score: this.order_details[i].score,
            comments: this.order_details[i].comments
          })
        }
        let three = document.getElementById('three');
        three.style.position = 'absolute';
        this.currentStep = '3/4'
        return
      }

      if (this.currentStep === '3/4'){
        if (this.payload.recommendation_score === 0) {
          this.$toast.error(this.$t('modalqualify.txt_por_favor_seleccione_una_opcion'))
          return
        }

        console.log("manda review", this.payload)
        this.loading = true
        let response = await OrderServices.uploadOrderComments(this.payload)
        if (response.data.success) {
          this.$toast.success(this.$t('modalqualify.txt_gracias_por_tu_resena'))
          let four = document.getElementById('four');
          four.style.position = 'absolute';
          this.currentStep = '4/4'
        } else {
          this.$toast.error(response.data.message)
        }
        this.loading = false
        return
      }
      if (this.currentStep === '4/4') {
        this.closeModal()
      }
    },
    backNextStep() {
      if (this.currentStep === '2/4') {
        this.currentStep = '1/4'
        this.title_qualify_modal = this.$t('modalqualify.txt_que_nos_falto_para_ser_excelentes')
      }
      if (this.currentStep === '3/4'){
        this.currentStep = '2/4'
      }
    },
    closeModal() {
      this.$emit("close")
    },
  },
  computed: {
  }
}
</script>

<style scoped>
.qualify__container{
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: auto;
  max-height: 85vh;
  overflow-y: auto;
  background-color: var(--text-white);
  border-radius: 16px;
  color: var(--text-black);
}

.container{ padding: 20px 5px; }

.qualify__close{
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-size: .875rem;
  font-weight: var(--medium);
  width: max-content;
}

.icon-close{
  color: var(--text-black);
  cursor: pointer;
  scale: 1;
  transition: scale .3s linear;
}

.icon-close:hover{
  scale: 1.5;
  color: var(--primary);
}

.qualify__close p{ display: none; }

.qualify__titles{ text-align: center; align-self: center}

.qualify__titles p{ font-weight: var(--medium); }

.qualify__title{
  width: 90%;
  margin-inline: auto;
  font-size: 1.125rem;
}

.starts-2{
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.starts{
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  padding: 20px 10px;
  margin-bottom: 20px;
}

.start{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.vuelottie{
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.start-p{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.start-p p{
  font-size: .875rem;
  color: #555555;
}

.lottie-animation-container{ cursor: pointer;}

.starts svg,
.starts-2 svg{ color: var(--border-gray);}

.start p{
  font-size: .875rem;
  color: #555555;
}

.platos__wrapper{
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: auto;
  max-height: 310px;
  overflow-y: auto;
  padding: 5px;
}

.platos{
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: 0 0 8px rgba(41, 41, 41, .2);
  border-radius: 8px;
  padding: 10px;
}

.platos-icon{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.platos-icon p{ font-size: .875rem; }

.platos-icon svg{
  color: var(--primary);
  cursor: pointer;
}

.chips__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  margin-bottom: 20px;
}

.addcoment{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--complementary);
  font-size: 1rem;
  font-weight: var(--semibold);
  transition: color .3s linear;
  margin-top: 20px;
  cursor: pointer;
}

.input-container{
  width: 100%;
}

.recomendar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.recomendar p{ font-size: .875rem; }

.buttons{
  position: sticky;
  left: 0;
  bottom: 0;
  background-color: var(--text-white);
  padding: 5px;
  box-shadow: 0 0 8px rgba(41, 41, 41, .2);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.button-active{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.buttons .button-text{ display: block; }

.buttons button{ width: 90%; }

.container__animation{
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.chips__wrapper-title{
  display: block !important;
  height: 0;
  overflow: hidden;
  transition: height 1.2s ease;
}

.chips__wrapper-title-active{ height: max-content; }

.two h3,
.three h3,
.four h3{
  text-align: center;
  margin-bottom: 10px;
  font-size: 1rem;
}

.two h4,
.three h4,
.four h4{
  text-align: center;
  margin-bottom: 20px;
  font-size: 1rem;
}

.five{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.five h3{
  text-align: center;
  font-size: 1.375rem;
}

.five p{ text-align: center; }

.coment-input{
  width: 100%;
  cursor: pointer;
  color: #212121;
  transition: color .3s linear;
}

.coment-input:hover{ color: var(--primary); }

.coment-input p{ display: none; }

/*  .coment-input p{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--complementary);
    font-size: 1rem;
    font-weight: var(--semibold);
    transition: color .3s linear;
    cursor: pointer;
  }*/

@media only screen and (min-width: 425px){
  .lottie-animation-container{
    width: 62px;
    height: 62px;
  }
  .recomendar{ justify-content: space-evenly; }
}

@media only screen and (min-width: 768px){
  .container{ padding: 30px 30px 10px; }
  .platos{ gap: 20px 30px;}
  .platos p{ font-size: 1rem; }
  .lottie-animation-container{
    width: 82px;
    height: 82px;
  }
  .recomendar p{ font-size: 1rem; }
  .addcoment{
    font-size: 1.125rem;
    cursor: pointer;
  }
  .addcoment:hover{ color: var(--primary); }
  .chips__wrapper{ justify-content: center; }
}

@media only screen and (min-width: 1024px){
  .qualify__container{
    width: 90%;
    height: auto;
    max-height: 90vh;
    gap: 30px;
    padding: 40px 60px;
  }
  .qualify__title{
    max-width: 760px;
    font-size: 1.75rem;
  }
  .starts{
    justify-content: center;
    margin-bottom: 30px;
  }
  .start{ margin-bottom: 0; }
  .input-container{ margin-top: 30px; }
  .recomendar{ justify-content: space-between; }
  .recomendar p{ font-size: 1.125rem; }
  .coment-input .input-container{ margin-top: 0; }
  .two,
  .three,
  .four,
  .five{ padding-bottom: 0px; }
  .two h3,
  .three h3,
  .four h3{ font-size: 1.375rem; }
  .two h4,
  .three h4,
  .four h4{ font-size: 1.125rem; }
  .buttons{
    position: relative;
    background-color: transparent;
    padding: 0;
    box-shadow: none;
    width: 100%;
  }
  .buttons button{ width: max-content; }
  .platos__wrapper{
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: auto;
    max-height: 100%;
    overflow-y: hidden;
    padding: 0;
  }
  .platos{
    display: grid;
    grid-template-columns: 180px 450px auto;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    border-radius: 0;
    padding: 10px;
  }
  .platos-icon svg{ display: none; }
  .coment-input p{
    max-width: 225px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

@media only screen and (min-width: 1280px){
  .qualify__container{
    width: 1100px;
    gap: 30px;
    padding: 40px 60px;
  }
}

@-webkit-keyframes scale-up-ver-top {
  0% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}
@keyframes scale-up-ver-top {
  0% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}
</style>