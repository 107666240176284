<template>
  <div class="container_table table__responsive">
    <div class="pedido-button">
      <p class="tp-title-responsive">Tu pedido</p>
      <a :href="invoice_pdf" target="_blank" style="display: none" id="invoice_pdf_link"></a>
      <ButtonPrimary
          type="button"
          v-if="invoice_pdf"
          @click="downloadInvoiceClicked"
      >
        {{ $t('ordeinfocomponent.txt_descargar_comprobante') }}
      </ButtonPrimary>
    </div>
    <div class="table__responsive">
      <table class="table">
        <tbody>
          <tr
              v-for="(item,key) in details"
              :key="key"
              class="table_item"
          >
            <td class="column_producto item_producto">
              <img
                  :src="item.product_img"
                  alt="imagen"
                  class="imagen"
              >
            </td>
            <td class="column_producto item_producto_info" >
              <p class="tp-subtitle-responsive">{{item.product}}</p>
              <p class="tp-subtitle-responsive">{{item.product_size_names}}</p>
              <div class="products">
                <ul class="tp-subtitle-responsive" v-show="item.product_sizes2_names != null && item.product_sizes2_names.length > 0">{{ $t('orderdatailcomponent.txt_elegiste') }}
                  <li class="tp-body-responsive">
                    {{item.product_sizes2_names}}
                  </li>
                </ul>
              </div>
              <div class="products">
                <ul class="tp-subtitle-responsive" v-show="item.product_sizes3_names != null && item.product_sizes3_names.length > 0">{{ $t('orderdatailcomponent.txt_elegiste') }}
                  <li class="tp-body-responsive">
                    {{item.product_sizes3_names}}
                  </li>
                </ul>
              </div>
              <div class="products">
                <ul class="tp-subtitle-responsive" v-show="item.product_sizes4_names != null && item.product_sizes4_names.length > 0">{{ $t('orderdatailcomponent.txt_elegiste') }}
                  <li class="tp-body-responsive">
                    {{item.product_sizes4_names}}
                  </li>
                </ul>
              </div>
              <div class="products">
                <ul class="tp-subtitle-responsive" v-show="item.ingredients_add.length>0">{{ $t('orderdatailcomponent.txt_elegiste') }}
                  <li class="tp-body-responsive">
                    {{item.ingredients_add}}
                  </li>
                </ul>
              </div>
              <div class="products">
                <ul class="tp-subtitle-responsive" v-show="item.item_discount > 0">{{ $t('orderdatailcomponent.txt_descuento_double_dat') }}
                  <li class="tp-body-responsive">
                    {{item.item_discount}}
                  </li>
                </ul>
              </div>
            </td>
            <td class="column_producto item_cantidad tp-subtitle-responsive">{{item.cant}} und
            </td>
            <td class="column_subtotal item_subtotal tp-body-responsive">S/ . {{(parseFloat(item.subtotal) - parseFloat(item.item_discount)).toFixed(2)}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="costos">
      <div>
        <p class="tp-subtitle-responsive">{{ $t('modalmicarrito.txt_subtotal') }}</p>
        <p class="tp-subtitle-responsive">S/ . {{subtotal.toFixed(2)}}</p>
      </div>
      <div v-if="type_delivery==='3'">
        <p class="tp-subtitle-responsive">{{ $t('orderdatailcomponent.txt_costo_de_envio') }}</p>
        <p class="tp-subtitle-responsive">S/ . {{(parseFloat(delivery)+parseFloat(commission)).toFixed(2)}}</p>
      </div>
      <div v-if="type_delivery==='2'&&commission>0">
        <p class="tp-subtitle-responsive">{{ $t('orderdatailcomponent.txt_bolsa_compostable') }}</p>
        <p class="tp-subtitle-responsive">S/ . {{parseFloat(commission).toFixed(2)}}</p>
      </div>
      <div v-if="coupon_title != null || descuento > 0">
        <p class="tp-subtitle-responsive">{{ $t('orderdatailcomponent.txt_descuento') }}</p>
        <p class="tp-subtitle-responsive">S/ -{{descuento.toFixed(2)}}</p>
      </div>
      <div>
        <p class="tp-subtitle-responsive">{{ $t('orderdatailcomponent.txt_total') }}</p>
        <p class="tp-subtitle-responsive">S/ . {{total_coupon.toFixed(2)>0?total_coupon.toFixed(2):'0.00'}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import OrderServices from "@/services/order-services"
import ButtonPrimary from "@/components/new/buttons/ButtonPrimary.vue";

export default {
  components: {
    ButtonPrimary
  },
  props: [
    'delivery', 'total_coupon', 'commission', 'type_delivery', 'coupon_title', 'from_tracking', 'order_id',
    'invoice_pdf'
  ],
  data () {
    return {
      details: [],
      qr_code: '',
    }
  },
  async mounted () {
    let response
    if (this.from_tracking) {
      let url_string = window.location.href;
      let url = new URL(url_string);
      this.qr_code = url.searchParams.get("qr_code");
      response = await OrderServices.detailOrderWithQr({ qr_code: this.qr_code })
    } else {
      console.log("order id", this.order_id)
      response = await OrderServices.detailOrder({order: this.order_id})
    }
    if (response.data.success) {

      console.log('detalle orden', response.data)
      this.details = response.data.data
    }
  },
  methods: {
    downloadInvoiceClicked() {
      document.getElementById('invoice_pdf_link').click()

    }
  },
  computed: {
    subtotal () {
      let suma = 0
      for (let i = 0; i < this.details.length; i++) {
        suma = suma + parseFloat(this.details[i].subtotal) - parseFloat(this.details[i].item_discount)
      }
      return suma
    },
    descuento () {
      return this.total - this.total_coupon // costo de productos menos sus item_discount. Despues se suma delivery.
      // Finalmente se le resta total_coupon (lo que el cliente realmente paga)
    },
    total () { // Precios reales (lista - descuento por item) + delivery
      let aux = this.subtotal
      switch (this.type_delivery) {
        case '3':
          return aux + parseFloat(this.delivery) + parseFloat(this.commission)
        case '2'://recojo
          return aux + parseFloat(this.commission)
        default:
          return aux

      }
    }
  }
}
</script>

<style scoped >
  .pedido-button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
  }

  .column_producto img{
    min-width: 100px;
    height: 100px;
    border-radius: 8px;
    display: block;
  }

  .container_table{
    border-radius: 8px;
    border: 1px solid var(--border-gray);
    padding: 20px;
  }

  tbody td{
    padding: 10px;
  }

  .table_item td:nth-child(3){
    font-weight: var(--semibold);
    text-align: center;
  }


  .table_item td:nth-child(4){ text-align: right; }

  .item_producto{ width: 120px; }

  .item_producto_info{  width: 250px; }

  .item_producto_info tr{
    color: var(--text-black);
    font-size: 1rem;
    font-weight: var(--regular);
  }

  .item_prducto_info tr:first-child{
    width: 200px;
    font-weight: var(--medium);
  }

  .products{
    padding-left: 20px;
    font-size: 1rem;
  }

  .products li{
    font-size: 1rem;
    font-weight: var(--regular);
    list-style: initial;
  }

  .costos{
    width: 85%;
    margin-left: auto;
  }

  .costos div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 10px 0px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid var(--border-gray);
    color: var(--text-black);
  }

  .costos div:last-child{
    border-bottom: 1px solid transparent;
  }

  @media only screen and (max-width: 1024px){
    .costos{ width: 100%; }
  }

  @media only screen and (max-width: 425px){
    .item_producto:first-child{ display: none; }
  }

  @media only screen and (max-width: 390px){
    .products ul{ padding-left: 10px; }
    .products li{ font-size: .875rem; }
  }
</style>