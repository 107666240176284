<template>
  <article class="carta__card" style="background-image: url({{category.img}})" data-aos="fade-up">
    <div class="carta__nombre">
      <h3>{{ category.name.length > 40 ? category.name.slice(0, 40) : category.name }}</h3>
    </div>
    <div class="carta__hover">
      <h3>{{ category.name.length > 40 ? category.name.slice(0, 40) : category.name }}</h3>
      <ButtonPrimary>{{ $t('buttons.txt_ordena_ahora') }}</ButtonPrimary>
    </div>
  </article>
</template>

<script>
import ButtonPrimary from "@/components/new/buttons/ButtonPrimary.vue";

export default {
  name: 'CategoriasCarta',
  components: {ButtonPrimary},
  props: ['category'],
}

</script>

<style scoped>
  .carta__card{
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 115px;
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden;
  }

  .carta__nombre{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 8px 16px;
    box-shadow: 0px -80px 40px rgba(0, 0, 0, .6) inset;
    opacity: 1;
    transition: opacity .3s linear;
    display: flex;
  }

  .carta__nombre h3{
    color: var(--text-white);
    text-align: left;
    font-family: var(--second-typography);
    font-size: var(--fs-head-small);
    font-weight: var(--bold);
    line-height: 32px;
    margin-top: auto;
  }

  .carta__hover{
    display: none;
  }

  .carta__hover h3{
    color: var(--text-white);
    text-align: center;
    font-family: var(--second-typography);
    font-size: var(--fs-head-small);
    font-weight: var(--bold);
    line-height: 32px;
  }

  @media screen and (min-width: 360px){
    .carta__card{
      height: 130px;
    }
  }

  @media screen and (min-width: 905px){
    .carta__card{
      height: 160px;
    }

    .carta__card:hover {
      flex-direction: column-reverse
    }

    .carta__card:hover .carta__nombre {
      top: -100%;
      opacity: 0
    }

    .carta__nombre h3{
      font-size: var(--fs-head-medium);
      line-height: 36px;
    }

    .carta__card:hover .carta__nombre{
      display: none;
    }

    .carta__hover{
      position: absolute;
      top: -100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      width: 100%;
      height: 100%;
      opacity: 0;
      box-shadow: inset 0 0 400px rgba(0,0,0,.8);
      transition: opacity .3s linear
    }

    .carta__card:hover .carta__hover {
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      opacity: 1
    }
  }

  @media screen and (min-width: 1240px){
    .carta__card{
      height: 200px;
    }
  }

  @media screen and (min-width: 1440px){
    .carta__card{
      height: 225px;
    }

    .carta__nombre{
      padding: 20px;
    }

    .carta__nombre h3{
      font-size: var(--fs-head-large);
      line-height: 40px;
    }

    .carta__hover h3{
      font-size: var(--fs-head-medium);
      line-height: 36px;
    }
  }
</style>