<template>
  <div class="add-orders">
    <p class="tp-body-responsive">{{ $t('perfilmispedidostab1sp.txt_conoce_la_carta_personaliza') }}</p>
    <ButtonPrimary
        type="button"
        @click="$emit('close');this.$router.push({name: 'menudigital'})"
    >
      {{ $t('buttons.txt_comenzar_ahora') }}
    </ButtonPrimary>
  </div>
</template>

<script>
import ButtonPrimary from "@/components/new/buttons/ButtonPrimary.vue";

export default {
  components: {ButtonPrimary}

}
</script>

<style scoped>
.add-orders{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  width: 100%;
  border: 1px dashed var(--border-gray);
  border-radius: 8px;
  padding: 20px 10px;
  color: var(--text-black);
  transition: all .3s linear;
  cursor: pointer;
}

.add-orders p{
  margin-bottom: 10px;
  text-align: center;
}

@media screen and (min-width: 1240px){
  .add-orders{
    margin-top: 60px;
    width: 625px;
    margin-inline: auto;
  }
}
</style>