<template>
  <div class="perfil_mis_direccioens">
    <p class="tp-body-responsive">{{ $t('perfil.txt_gestionar_tus_direcciones') }}</p>
    <div class="list-address">
      <div
          class="item-address"
          v-for="(item,key) in listAddress"
          :key="key"
      >
        <div class="icons-personalization">
          <svg v-if="item.name==='Home'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.99993 19V11.625L2.99994 12.4C2.7666 12.5667 2.5166 12.6333 2.24993 12.6C1.98327 12.5667 1.7666 12.4333 1.59993 12.2C1.43327 11.9667 1.3666 11.7167 1.39993 11.45C1.43327 11.1833 1.55827 10.9667 1.77493 10.8L3.99993 9.1V7C3.99993 6.71667 4.09577 6.47917 4.28744 6.2875C4.4791 6.09583 4.7166 6 4.99993 6C5.28327 6 5.52077 6.09583 5.71243 6.2875C5.9041 6.47917 5.99993 6.71667 5.99993 7V7.575L10.7749 3.925C11.1416 3.64167 11.5499 3.5 11.9999 3.5C12.4499 3.5 12.8583 3.64167 13.2249 3.925L22.2249 10.8C22.4416 10.9667 22.5666 11.1833 22.5999 11.45C22.6333 11.7167 22.5666 11.9667 22.3999 12.2C22.2333 12.4167 22.0166 12.5417 21.7499 12.575C21.4833 12.6083 21.2416 12.5417 21.0249 12.375L19.9999 11.625V19C19.9999 19.55 19.8041 20.0208 19.4124 20.4125C19.0208 20.8042 18.5499 21 17.9999 21H5.99993C5.44993 21 4.9791 20.8042 4.58744 20.4125C4.19577 20.0208 3.99993 19.55 3.99993 19ZM5.99993 19H10.9999V16C10.9999 15.7167 11.0958 15.4792 11.2874 15.2875C11.4791 15.0958 11.7166 15 11.9999 15C12.2833 15 12.5208 15.0958 12.7124 15.2875C12.9041 15.4792 12.9999 15.7167 12.9999 16V19H17.9999V10.1L11.9999 5.525L5.99993 10.1V19ZM5.29993 5C4.9166 5 4.62077 4.84167 4.41244 4.525C4.2041 4.20833 4.1916 3.88333 4.37493 3.55C4.65827 3.06667 5.0291 2.6875 5.48743 2.4125C5.94577 2.1375 6.44993 2 6.99993 2C7.18327 2 7.35827 1.95417 7.52493 1.8625C7.6916 1.77083 7.8166 1.64167 7.89994 1.475C7.98327 1.325 8.09577 1.20833 8.23744 1.125C8.3791 1.04167 8.5416 1 8.72494 1C9.10827 1 9.39994 1.15833 9.59994 1.475C9.79994 1.79167 9.80827 2.11667 9.62494 2.45C9.3416 2.93333 8.97077 3.3125 8.51244 3.5875C8.0541 3.8625 7.54993 4 6.99993 4C6.8166 4 6.6416 4.04167 6.47494 4.125C6.30827 4.20833 6.18327 4.34167 6.09994 4.525C6.0166 4.675 5.90827 4.79167 5.77493 4.875C5.6416 4.95833 5.48327 5 5.29993 5Z" fill="currentColor"/>
          </svg>
          <svg v-else-if="item.name==='Work'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 21C3.45 21 2.97917 20.8042 2.5875 20.4125C2.19583 20.0208 2 19.55 2 19V8C2 7.45 2.19583 6.97917 2.5875 6.5875C2.97917 6.19583 3.45 6 4 6H8V4C8 3.45 8.19583 2.97917 8.5875 2.5875C8.97917 2.19583 9.45 2 10 2H14C14.55 2 15.0208 2.19583 15.4125 2.5875C15.8042 2.97917 16 3.45 16 4V6H20C20.55 6 21.0208 6.19583 21.4125 6.5875C21.8042 6.97917 22 7.45 22 8V19C22 19.55 21.8042 20.0208 21.4125 20.4125C21.0208 20.8042 20.55 21 20 21H4ZM4 19H20V8H4V19ZM10 6H14V4H10V6Z" fill="currentColor"/>
          </svg>
          <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 19.35C14.0333 17.4833 15.5417 15.7875 16.525 14.2625C17.5083 12.7375 18 11.3833 18 10.2C18 8.38333 17.4208 6.89583 16.2625 5.7375C15.1042 4.57917 13.6833 4 12 4C10.3167 4 8.89583 4.57917 7.7375 5.7375C6.57917 6.89583 6 8.38333 6 10.2C6 11.3833 6.49167 12.7375 7.475 14.2625C8.45833 15.7875 9.96667 17.4833 12 19.35ZM12 21.325C11.7667 21.325 11.5333 21.2833 11.3 21.2C11.0667 21.1167 10.8583 20.9917 10.675 20.825C9.59167 19.825 8.63333 18.85 7.8 17.9C6.96667 16.95 6.27083 16.0292 5.7125 15.1375C5.15417 14.2458 4.72917 13.3875 4.4375 12.5625C4.14583 11.7375 4 10.95 4 10.2C4 7.7 4.80417 5.70833 6.4125 4.225C8.02083 2.74167 9.88333 2 12 2C14.1167 2 15.9792 2.74167 17.5875 4.225C19.1958 5.70833 20 7.7 20 10.2C20 10.95 19.8542 11.7375 19.5625 12.5625C19.2708 13.3875 18.8458 14.2458 18.2875 15.1375C17.7292 16.0292 17.0333 16.95 16.2 17.9C15.3667 18.85 14.4083 19.825 13.325 20.825C13.1417 20.9917 12.9333 21.1167 12.7 21.2C12.4667 21.2833 12.2333 21.325 12 21.325ZM12 12C12.55 12 13.0208 11.8042 13.4125 11.4125C13.8042 11.0208 14 10.55 14 10C14 9.45 13.8042 8.97917 13.4125 8.5875C13.0208 8.19583 12.55 8 12 8C11.45 8 10.9792 8.19583 10.5875 8.5875C10.1958 8.97917 10 9.45 10 10C10 10.55 10.1958 11.0208 10.5875 11.4125C10.9792 11.8042 11.45 12 12 12Z" fill="currentColor"/>
          </svg>
        </div>
        <div class="address-informative">
          <p class="tp-subtitle-responsive">{{getType(item.name)}}</p>
          <p class="tp-body-responsive">{{getFullAddress(item)}}</p>
        </div>
        <svg class="delete-address" @click="selected_address=item; showModalDeleteAddress=true" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 21C6.45 21 5.97917 20.8042 5.5875 20.4125C5.19583 20.0208 5 19.55 5 19V6C4.71667 6 4.47917 5.90417 4.2875 5.7125C4.09583 5.52083 4 5.28333 4 5C4 4.71667 4.09583 4.47917 4.2875 4.2875C4.47917 4.09583 4.71667 4 5 4H9C9 3.71667 9.09583 3.47917 9.2875 3.2875C9.47917 3.09583 9.71667 3 10 3H14C14.2833 3 14.5208 3.09583 14.7125 3.2875C14.9042 3.47917 15 3.71667 15 4H19C19.2833 4 19.5208 4.09583 19.7125 4.2875C19.9042 4.47917 20 4.71667 20 5C20 5.28333 19.9042 5.52083 19.7125 5.7125C19.5208 5.90417 19.2833 6 19 6V19C19 19.55 18.8042 20.0208 18.4125 20.4125C18.0208 20.8042 17.55 21 17 21H7ZM17 6H7V19H17V6ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z" fill="currentColor"/>
        </svg>
      </div>
      <div
          class="add_address"
          @click="showModalAddAddress=true"
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11 13H6C5.71667 13 5.47917 12.9042 5.2875 12.7125C5.09583 12.5208 5 12.2833 5 12C5 11.7167 5.09583 11.4792 5.2875 11.2875C5.47917 11.0958 5.71667 11 6 11H11V6C11 5.71667 11.0958 5.47917 11.2875 5.2875C11.4792 5.09583 11.7167 5 12 5C12.2833 5 12.5208 5.09583 12.7125 5.2875C12.9042 5.47917 13 5.71667 13 6V11H18C18.2833 11 18.5208 11.0958 18.7125 11.2875C18.9042 11.4792 19 11.7167 19 12C19 12.2833 18.9042 12.5208 18.7125 12.7125C18.5208 12.9042 18.2833 13 18 13H13V18C13 18.2833 12.9042 18.5208 12.7125 18.7125C12.5208 18.9042 12.2833 19 12 19C11.7167 19 11.4792 18.9042 11.2875 18.7125C11.0958 18.5208 11 18.2833 11 18V13Z" fill="currentColor"/>
        </svg>
        <p class="tp-body-responsive">{{ $t('perfil.txt_anadir_una_direccion_de_entrega') }}</p>
      </div>
    </div>
  </div>
  <transition name="slide-fade">
    <ModalAddAddress
        v-if="showModalAddAddress"
        @close="showModalAddAddress=false;loadAddress()"
    ></ModalAddAddress>
  </transition>
  <transition name="slide-fade">
    <TwoButtonGenericModal
        v-if="showModalDeleteAddress"
        :title="this.$t('buttons.txt_confirmar')"
        :content="this.$t('perfil.txt_deseas_eliminar_la_direccion', { address: selected_address.address })"
        :accept="this.$t('barralateralizquierda.txt_si_salir')"
        :cancel="this.$t('perfil.txt_no_regresar')"
        @close_modal="showModalDeleteAddress=false"
        @accepted_modal="deleteAddress"
    >
    </TwoButtonGenericModal>
  </transition>
</template>

<script>
import UserServices from "@/services/user-services"
import {defineAsyncComponent} from "vue";
import TwoButtonGenericModal from "@/components/MODALS/TwoButtonGenericModal";
export default {
  components: {
    TwoButtonGenericModal,
    ModalAddAddress: defineAsyncComponent(() => import("./PerfilMiCuentaLugaresAddAddress")),
  },
  data () {
    return {
      showModalAddAddress: false,
      listAddress: [],
      showModalDeleteAddress: false,
      selected_address: [],
    }
  },
  created () {

  },
  async mounted () {

    await this.loadAddress()
  },
  methods: {
    async loadAddress () {
      try {
        let response = await UserServices.listAddress()
        if (response.data.success) {
          console.log('list', response.data.data)
          this.listAddress = response.data.data
        } else {
          //mostrar modal error
        }
      } catch (error) {
        console.log(error)
      }
    },
    async deleteAddress () {
      try {
        let response = await UserServices.deleteAddress(this.selected_address.id)
        if (response.data.success) {
          this.$toast.success(this.$t('perfilmicuentalugares.txt_direccion_eliminada'))
        } else {
          this.$toast.error(response.data.message)
        }
        await this.loadAddress()
      } catch (error) {
        console.log(error)
      }
      this.showModalDeleteAddress = false
    },
    getType (value) {
      switch (value) {
        case 'Work': return this.$t('modaldelivery.txt_trabajo')
        case 'Home': return this.$t('modaldelivery.txt_casa')
        default: return value;
      }
    },
    getFullAddress(item) {
      let full_address = item.address
      if (item.district) {
        full_address = `${full_address}, ${item.district}`
      }
      if (item.reference) {
        full_address = `${full_address}. ${this.$t('dropdownaddress.txt_referencia')}: ${item.reference}`;
      }
      return full_address
    },
  },
}
</script>

<style scoped>
.perfil_mis_direccioens > p{
  margin-bottom: 20px;
  color: var(--text-black);
}

.list-address{
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
}

.item-address{
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  background-color: var(--text-white);
  padding: 4px 8px;
  min-height: 70px;
}

.item-address svg{
  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;
}

.address-informative{
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.delete-address{
  color: var(--text-black);
  transition: all .3s linear;
  margin-left: auto;
}

.add_address{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  width: 100%;
  border: 1px dashed;
  border-radius: 8px;
  padding: 10px;
  color: var(--text-black);
  opacity: .6;
  transition: all .3s linear;
  cursor: pointer;
}

.add_address p{
  transition: color .3s linear;
}

.add_address:active,
.add_address p:active{
  color: var(--primary);
}

@media screen and (min-width: 600px){
  .list-address{
    gap: 24px;
  }
}

@media screen and (min-width: 905px){
  .item-address{
    gap: 16px;
    padding: 14px 12px;
  }

  .delete-address:hover{
    cursor: pointer;
    color: var(--primary);
  }

  .add_address:hover,
  .add_address:hover p{
    color: var(--primary);
    cursor: pointer;
    opacity: 1;
  }
}

@media screen and (min-width: 1240px){
  .item-address{
    gap: 20px;
  }

  .item-address,
  .add_address{
    width: 480px;
    max-width: 500px;
  }
}

</style>