<template>
  <div class="modal__bg">
    <img src="../../assets/images/simbolo_barrilito.svg" alt="logo">
    <span class="tp-head-responsive">{{ $t('forgotpassword.txt_cargando_dat') }}</span>
  </div>
</template>

<script>
export default {
  name: "LoadingBigComponent",
}
</script>

<style scoped>

.modal__bg {
  background: #ffffffCC;
  z-index: 2;
}

img {
  display: block;
  height: 80px;
  animation: scale 2s linear infinite;
}

.tp-head-responsive {
  margin-top: 15px;
}

@media only screen and (min-width: 600px) {
  .tp-head-responsive {
    margin-top: 0;
    margin-left: 40px;
  }
}

@keyframes scale {
  0% {
    transform: scale(.8);
  }

  50%{
    transform: scale(1);
  }

  100% {
    transform: scale(.8);
  }
}
</style>