<template>
  <div class="input-container">
    <input
        class="input__class tp-body-responsive"
        :class="{'activated-disabled': (modelValue != null && modelValue.length > 0 && disabled)}"
        :value="modelValue"
        :placeholder="placeholder"
        @input="onChanged"
        :disabled="disabled"
        :required="required"
        :maxlength="maxlength"
        :minlength="minlength"
        :type="type"
        :readonly="readonly"
        @keydown.enter.prevent="onEnterPressed"
    />
    <label
        class="input-label tp-label-responsive"
        :class="{'activated': (modelValue != null && modelValue.length > 0)}"
        v-if="showLabel"
        :style="{background : `linear-gradient(180deg, var(text-white) 0%, var(text-white) 45%, white 45.1%, white 100%)`}"
    >
      {{ placeholder }}
    </label>
  </div>
</template>

<script>
import { defineComponent } from "vue"
export default defineComponent({
  name: "InputComponent",

  emits: ['update:modelValue', 'inputUpdate', 'enterPressed'],

  props: {
    modelValue: String,
    placeholder: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    bg_color: {
      type: String,
      default: 'white'
    },
    maxlength: {
      type: String,
      default: '1000'
    },
    minlength: {
      type: String,
      default: '1'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    showLabel: {
      type: Boolean,
      default: true
    }

  },

  setup(props, { emit }) {
    function onChanged(e) {
      emit('update:modelValue', e.currentTarget.value);
      emit('inputUpdate')
    }

    function onEnterPressed(e) {
      emit('update:modelValue', e.currentTarget.value);
      emit('inputUpdate')
      emit('enterPressed')
    }

    return {
      onChanged, onEnterPressed
    }
  }
})
</script>

<style scoped>
</style>